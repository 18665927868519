import React, { createContext, useReducer } from 'react';

import {
  combinedReducers,
  SectionNavActions,
  PortalNavActions,
  MembershipAction,
  UserEmailAction,
  UserIdAction,
} from './reducers';
import { MemebershipTypes, PortalSectionTypeEnum, SectionTypeEnum } from 'strings';
import { testUserEmail, useTestUser } from 'sitevars';

type InitialStateType = {
  sectionNav: string;
  portalNav: string;
  membership: string;
  userEmail: string | null | undefined;
  userId: number | null;
};

const initialState = {
  sectionNav: SectionTypeEnum.PORTAL,
  portalNav: PortalSectionTypeEnum.HOME,
  membership: MemebershipTypes.INDIVIDUAL,
  userEmail: useTestUser ? testUserEmail : null,
  userId: null,
};

type ProviderProps = {
  children: React.ReactNode;
};

const AppContext = createContext<{
  state: InitialStateType;
  dispatch: React.Dispatch<SectionNavActions | PortalNavActions | MembershipAction | UserEmailAction | UserIdAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

const AppProvider = ({ children }: ProviderProps) => {
  const [state, dispatch] = useReducer(combinedReducers, initialState);

  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};

export { AppContext, AppProvider };
