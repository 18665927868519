import { Stack } from '@mui/material';
import JobsTable from 'components/JobsTable';
import { PageHeader } from 'components/common/PageHeader';

export default function JobsTracker() {
  return (
    <div>
      <Stack spacing={1} sx={{ backgroundColor: '', paddingBottom: 0 }}>
        <PageHeader title="Jobs Applications Tracker" />
        <JobsTable />
      </Stack>
    </div>
  );
}
