import React, { createContext, useContext, useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { localStorageKeys, routes } from 'sitevars';

interface User {
  email: string;
  isAdmin: boolean;
  [key: string]: any;
}

interface AuthContextType {
  user: User | null;
  login: (user: User) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(() => {
    const savedUser = localStorage.getItem(localStorageKeys.userData);
    return savedUser ? JSON.parse(savedUser) : null;
  });

  // TODO this method is not using and it is in conflict with the current user logic
  const login = (user: User) => {
    setUser(user);
    localStorage.setItem(localStorageKeys.userData, JSON.stringify(user));
  };

  // TODO this method is not using and it is in conflict with the current user logic
  const logout = () => {
    setUser(null);
    localStorage.clear();
    navigate(routes.root);
  };

  return <AuthContext.Provider value={{ user, login, logout }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
