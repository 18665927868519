import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import NavigateNextSharpIcon from '@mui/icons-material/NavigateNextSharp';
import { DialogContentText, Tooltip, useTheme } from '@mui/material';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
  GridCellParams,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { apiServerLink, routes } from 'sitevars';
import AlertConfirmation from './common/AlertConfirmation';
import { useEffect, useState } from 'react';
import { jobApplicationOutcome, jobStageOptions, jobStatusOptions, JobSummary } from 'interfaces/interfaces';
import axios from 'axios';
import { AppContext } from 'context/context';
import { generateId, getUserId } from 'utils/Helpers';
import ToastAction from './common/ToastAction';
import { NotificationTypeEnum } from 'strings';

interface JobsTableProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
}

function JobsTable(props: JobsTableProps) {
  const { setRows, setRowModesModel } = props;
  const { state } = React.useContext(AppContext);
  const theme = useTheme();
  //notification toast vars
  const [notification, setNotification] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>('');
  const [notificationType, setNotificationType] = useState<
    typeof NotificationTypeEnum[keyof typeof NotificationTypeEnum]
  >(NotificationTypeEnum.INFO);

  const addNewJob = async () => {
    try {
      const samplePayload = {
        user_id: getUserId(state),
        company: '',
        role: '',
        status: '',
        date: '',
        application_type: '',
        outcome: '',
        interview_reflection_rating: '',
        interview_reflection_notes: '',
        offer_date: '',
        offer_base: '',
        offer_bonus: '',
        offer_equity: '',
        offer_notes: '',
      };
      const response = await axios.post(`${apiServerLink}/api/create_job`, samplePayload);
      if (response.status === 200 && response.data.job_id) {
        return response.data.job_id;
      } else {
        // notification for error
        setNotification(true);
        setNotificationText('Sorry something went wrong. Please try again.');
        setNotificationType(NotificationTypeEnum.ERROR);
      }
    } catch (error) {
      // console.error('Error creating a new job:', error);
      // notification for error
      setNotification(true);
      setNotificationText('Sorry something went wrong. Please try again.');
      setNotificationType(NotificationTypeEnum.ERROR);
    }
  };

  const handleAddNewJob = async () => {
    const id = await addNewJob();
    if (id && id !== undefined) {
      setRows((oldRows) => [{ id }, ...oldRows]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit },
      }));
    } else {
      setNotification(true);
      setNotificationText('Sorry something went wrong. Please try again.');
      setNotificationType(NotificationTypeEnum.ERROR);
    }
  };

  const handleCloseNotification = () => {
    setNotification(false);
    setNotificationText('');
    setNotificationType(NotificationTypeEnum.INFO);
  };

  return (
    <>
      {notification && (
        <ToastAction
          message={notificationText}
          type={notificationType}
          open={notification}
          close={handleCloseNotification}
        />
      )}

      <GridToolbarContainer>
        <Button
          startIcon={<AddIcon />}
          onClick={handleAddNewJob}
          sx={{
            textTransform: 'none',
            backgroundColor: theme.palette.primary.light,
            margin: 0,
            padding: 0,
            borderRadius: 30,
            paddingX: 1,
            fontWeight: 0,
            marginBottom: 1,
          }}
        >
          Add New Job Application
        </Button>
      </GridToolbarContainer>
    </>
  );
}

export default function FullFeaturedCrudGrid() {
  const navigate = useNavigate();
  const { state } = React.useContext(AppContext);
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [openDeleteAlert, setOpenDeleteAlert] = useState<boolean>(false);
  const [shouldDeleteJob, setShouldDeleteJob] = useState<boolean>(false);
  const [userId, setUserId] = useState<number | null>();
  const [selectedRow, setSelectedRow] = useState<GridRowModesModel | null>(null);
  const theme = useTheme();
  //notification toast vars
  const [notification, setNotification] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>('');
  const [notificationType, setNotificationType] = useState<
    typeof NotificationTypeEnum[keyof typeof NotificationTypeEnum]
  >(NotificationTypeEnum.INFO);

  const fetchJobs = async (user_id: number) => {
    try {
      const response = await axios.get(`${apiServerLink}/api/get_jobs/${user_id}`);
      const jobsData: JobSummary[] = response.data;

      const rows = jobsData.map((job, index) => ({
        id: job.id,
        company: job.company,
        role: job.role,
        status: job.status,
        date: job.date,
        outcome: job.outcome,
      }));

      setRows(rows);
    } catch (error) {
      // console.error('Error fetching jobs data:', error);
      // notification for error
      setNotification(true);
      setNotificationText('Sorry something went wrong. Please try again.');
      setNotificationType(NotificationTypeEnum.ERROR);
    }
  };

  const deleteJob = async (job_id: any) => {
    try {
      if (job_id && job_id !== undefined && typeof job_id === 'number') {
        const response = await axios.delete(`${apiServerLink}/api/delete_job/${userId}/${job_id}`);
        if (response.status === 200) {
          setRows(rows.filter((row) => row.id !== job_id));
          setSelectedRow(null);
          // notification for success
          setNotification(true);
          setNotificationText('Job was deleted successfully.');
          setNotificationType(NotificationTypeEnum.SUCCESS);
        } else {
          // notification for error
          setNotification(true);
          setNotificationText('Job was not deleted successfully. Please try again.');
          setNotificationType(NotificationTypeEnum.ERROR);
        }
      } else {
        // notification for error
        setNotification(true);
        setNotificationText('Job was not deleted successfully. Please try again.');
        setNotificationType(NotificationTypeEnum.ERROR);
      }
    } catch (error) {
      // console.error('Error fetching jobs data:', error);
      // notification for error
      setNotification(true);
      setNotificationText('Job was not deleted successfully. Please try again.');
      setNotificationType(NotificationTypeEnum.ERROR);
    }
  };

  useEffect(() => {
    const user_id = getUserId(state);
    setUserId(user_id);
    if (user_id) {
      fetchJobs(user_id);
    } else {
      // notification for error
      setNotification(true);
      setNotificationText('Sorry something went wrong. Please try again.');
      setNotificationType(NotificationTypeEnum.ERROR);
    }
  }, []);

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    const selectedRow = rows.filter((row) => row.id === id);
    if (selectedRow && selectedRow.length > 0) {
      setSelectedRow(selectedRow[0]);
      setOpenDeleteAlert(true);
    }
  };

  useEffect(() => {
    if (shouldDeleteJob) {
      setShouldDeleteJob(false);
      deleteJob(selectedRow?.id);
    }
  }, [shouldDeleteJob]);

  const handleMoreClick = (id: GridRowId) => () => {
    const path = `/jobs-tracker/${id}`; // TODO this is not working and appending the path to the current path when I pass navigate( `${routes.jobsTracker}/${id}`);
    navigate(path);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    try {
      const response = await axios.put(`${apiServerLink}/api/update_job/${userId}/${newRow.id}`, newRow);
      if (response.data && typeof response.data === 'object' && response.data.id) {
        const updatedJob = response.data;
        // If it's a new job or a job returned from the backend, update the rows. Otherwise it is a OK message after an existing job update
        const updatedRows = rows.map((row) => (row.id === newRow.id ? updatedJob : row));
        setRows(updatedRows);
        // notification for success
        setNotification(true);
        setNotificationText('Job updated successfully!');
        setNotificationType(NotificationTypeEnum.SUCCESS);
      }
      return { ...newRow };
    } catch (error) {
      console.error('Error updating job:', error);
      throw new Error('Failed to update job');
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = [
    { field: 'company', headerName: 'Company', width: 220, editable: true },
    { field: 'role', headerName: 'Role', width: 200, editable: true },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      editable: true,
      type: 'singleSelect',
      valueOptions: jobStatusOptions,
    },
    {
      field: 'date',
      headerName: 'Application Date',
      type: 'date',
      width: 150,
      editable: true,
      valueFormatter: (params: GridCellParams) => {
        if (params && params.value) {
          if (typeof params.value === 'string' || typeof params.value === 'number') {
            const date = new Date(params.value);
            return isNaN(date.getTime()) ? '' : date.toLocaleDateString();
          }
        }
        return '';
      },
      renderCell: (params: GridCellParams) => {
        if (params && params.value) {
          let date: Date;
          if (params.value instanceof Date) {
            date = params.value;
          } else if (typeof params.value === 'string' || typeof params.value === 'number') {
            date = new Date(params.value);
          } else {
            return '';
          }
          return isNaN(date.getTime()) ? '' : date.toLocaleDateString();
        }

        return '';
      },
    },
    {
      field: 'outcome',
      headerName: 'Outcome',
      width: 150,
      editable: true,
      type: 'singleSelect',
      valueOptions: jobApplicationOutcome,
    },
    // { field: 'stages-details', headerName: 'Stages Details', width: 200, editable: true },
    // { field: 'notes', headerName: 'Notes', width: 200, editable: true },
    // { field: 'feedback', headerName: 'Feedback', width: 200, editable: true },
    // { field: 'job-link', headerName: 'Job link', width: 200, editable: true },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <Tooltip title="Save changes">
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: 'primary.main',
                }}
                onClick={handleSaveClick(id)}
              />
            </Tooltip>,
            <Tooltip title="Cancel changes">
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />
            </Tooltip>,
          ];
        }

        return [
          <Tooltip title="Edit entry">
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />
          </Tooltip>,
          <Tooltip title="Delete entry">
            <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(id)} color="inherit" />
          </Tooltip>,
        ];
      },
    },
    {
      field: 'actions2',
      type: 'actions',
      headerName: '',
      width: 200,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <Button
            endIcon={<NavigateNextSharpIcon />}
            onClick={handleMoreClick(id)}
            sx={{
              textTransform: 'none',
              backgroundColor: theme.palette.primary.light,
              margin: 0,
              padding: 0,
              borderRadius: 30,
              paddingX: 1,
              fontWeight: 0,
              marginBottom: 1,
            }}
          >
            Add more details
          </Button>,
        ];
      },
    },
  ];

  function ShouldDeleteText() {
    return <DialogContentText>Would you like to delete this job application entry?</DialogContentText>;
  }

  const handleCloseNotification = () => {
    setNotification(false);
    setNotificationText('');
    setNotificationType(NotificationTypeEnum.INFO);
  };

  return (
    <>
      {notification && (
        <ToastAction
          message={notificationText}
          type={notificationType}
          open={notification}
          close={handleCloseNotification}
        />
      )}
      <Box
        sx={{
          height: '80vh',
          width: '80vw',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >
        {openDeleteAlert && (
          <AlertConfirmation
            text={<ShouldDeleteText />}
            open={openDeleteAlert}
            setOpen={setOpenDeleteAlert}
            status={shouldDeleteJob}
            confirmationFunction={setShouldDeleteJob}
            confirmationText="Yes, delete"
          />
        )}
        <DataGrid
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slots={{
            toolbar: JobsTable as GridSlots['toolbar'],
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel },
          }}
        />
      </Box>
    </>
  );
}
