import React from 'react';
import { BarChart } from '@mui/x-charts';
import { ChartProps } from './PieChartVisualsization';
import { Typography } from '@mui/material';
import { themeColors } from 'sitevars';

interface BarChartVisualizationProps {
  chartProps: ChartProps[];
  title?: string;
}

const BarChartVisualization: React.FC<BarChartVisualizationProps> = ({ chartProps, title }) => {
  const labels = chartProps.map((item) => item.label);
  const colors = chartProps.map((item) => item.color);
  const values = chartProps.map((item) => item.value);

  return (
    <>
      <Typography variant="h5">{title}</Typography>
      <BarChart
        xAxis={[
          {
            scaleType: 'band',
            data: labels,
            colorMap: {
              type: 'ordinal',
              values: labels,
              colors: colors,
            },
          },
        ]}
        yAxis={[{ scaleType: undefined }]}
        series={[{ data: values }]}
      />
    </>

    // <BarChart width={500} height={300}>
    //   <BarSeries
    //     dataKey="value"
    //     series={chartProps}
    //     labelKey="label"
    //     barSize={50}
    //     colors={chartProps.map(item => item.color)}
    //     animation
    //   />
    // </BarChart>
  );
};

export default BarChartVisualization;
