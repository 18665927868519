import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import HomeIcon from '@mui/icons-material/Home';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ProfileModal from './sections/ProfileModal';
import { useNavigate } from 'react-router-dom';
import { AppContext } from 'context/context';
import { Types } from 'context/reducers';
import { useContext } from 'react';
import { routes } from '../../sitevars';
import { SectionTypeEnum } from 'strings';
import logo from 'assets/topcoding-logo.png';
import { Tooltip } from '@mui/material';

const drawerWidth = 60;

const MenuSidebar = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);

  const shouldNavigate = (path: string | null) => {
    if (path) {
      navigate(path);
    }
  };

  const sidebarItems = [
    {
      text: 'Home',
      icon: <HomeIcon sx={{ color: '#fff' }} />,
      section: SectionTypeEnum.PORTAL,
      path: state.portalNav,
      toollip: 'Portal',
    },
    {
      text: 'Notifications',
      icon: <NotificationsIcon sx={{ color: '#fff' }} />,
      section: SectionTypeEnum.NOTIFICATIONS,
      path: null,
      toollip: 'Notifications',
    },
  ];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#0d87e9',
        width: drawerWidth,
        justifyContent: 'space-between',
        height: '100vh',
      }}
    >
      <List>
        {sidebarItems?.map((item) => (
          <Tooltip title={item.toollip} placement="right" arrow key={item.text}>
            <ListItem
              key={item.text}
              button
              onClick={() => {
                shouldNavigate(item.path);
                dispatch({
                  type: Types.SectionNavType,
                  payload: { sectionNav: item.section },
                });
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
            </ListItem>
          </Tooltip>
        ))}
        {/* <LeetcodeButton /> */}
      </List>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: '10px',
        }}
      >
        <div
          className="image-container"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '',
          }}
        >
          <img
            src={logo}
            alt="Rotated Image"
            className="rotate-left"
            style={{
              transform: 'rotate(-90deg)',
              width: '300px',
              paddingLeft: '140px',
              paddingBottom: '5px',
            }}
          />
        </div>
        <ProfileModal />
      </div>
    </div>
  );
};

export default MenuSidebar;
