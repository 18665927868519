import React, { useContext, useEffect, useState } from 'react';
import { Typography, Stack, Box, Grid } from '@mui/material';
import axios from 'axios';
import QuoteCard from 'components/QuoteCard';
import Spinner from 'components/common/Spinner';
import { AppContext } from 'context/context';
import { GoogleCalendarEvent, GoogleUserData, ProcessedEvent, UserData } from 'interfaces/interfaces';
import { localStorageKeys, apiServerLink, googleApiKey } from 'sitevars';
import coder from 'assets/coder.svg';
import DateCalendarServerRequest from 'components/common/Celendar';
import EventsCarousel from 'components/EventsCarousel';
import { getUserEmail } from 'utils/Helpers';

export default function Home() {
  const { state, dispatch } = useContext(AppContext);
  const [user, setUser] = useState<UserData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [googleUser, setGoogleUser] = useState<GoogleUserData | null>(null);
  const [calendarEvents, setCalendarEvents] = useState<ProcessedEvent[]>([]);

  const CALENDAR_ID = 'c_2a6b2f8c8911cb3454a052d11ee89b7dd9c79ee7ed733bf69685e7fc962a85b7@group.calendar.google.com';

  useEffect(() => {
    if (
      localStorage.getItem(localStorageKeys.googleUserData) !== '' ||
      localStorage.getItem(localStorageKeys.googleUserData) !== null ||
      localStorage.getItem(localStorageKeys.googleUserData) !== undefined
    ) {
      const storedData = localStorage.getItem(localStorageKeys.googleUserData);
      if (storedData) {
        const googleUserData = JSON.parse(storedData) as GoogleUserData;
        setGoogleUser(googleUserData);
        setLoading(false);
      }
    }

    const fetchUserData = async () => {
      try {
        const email = getUserEmail(state);
        if (email) {
          if (
            localStorage.getItem(localStorageKeys.userData) === '' ||
            localStorage.getItem(localStorageKeys.userData) === null ||
            localStorage.getItem(localStorageKeys.userData) === undefined
          ) {
            const response = await axios.get(`${apiServerLink}/api/user_data/${email}`);

            const userData = response.data[0];
            setUser(userData);
            localStorage.setItem(localStorageKeys.userData, JSON.stringify(userData));
            setLoading(false);
          } else {
            const storedData = localStorage.getItem(localStorageKeys.userData);

            if (storedData) {
              const userData = JSON.parse(storedData);
              setUser(userData);
              setLoading(false);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    fetchUserData();
  }, []);

  const preprocessEvents = (googleEvents: GoogleCalendarEvent[]): ProcessedEvent[] => {
    return googleEvents.map((event) => ({
      id: event.id,
      title: event.summary,
      start: new Date(event.start.dateTime),
      end: new Date(event.end.dateTime),
      description: event.description || '',
      meetLink: event.hangoutLink || '',
    }));
  };

  useEffect(() => {
    const data = localStorage.getItem(localStorageKeys.calendarEvents);
    if (data === '' || data === null || data === undefined || data.length <= 0) {
      fetchEvents();
    } else {
      const parsedData = JSON.parse(data);
      setCalendarEvents(parsedData);
    }

    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await axios.get(`https://www.googleapis.com/calendar/v3/calendars/${CALENDAR_ID}/events`, {
        params: {
          key: googleApiKey,
          timeMin: new Date().toISOString(),
          maxResults: 10,
          showDeleted: false,
          singleEvents: true,
          orderBy: 'startTime',
        },
      });
      const events = preprocessEvents(response.data.items);
      setCalendarEvents(events);
      localStorage.setItem(localStorageKeys.calendarEvents, JSON.stringify(events));
    } catch (e) {
      console.error('Error occured while fetching google events', e);
    }
  };

  return (
    <Stack sx={{ backgroundColor: '', height: '90vh', width: '90vw', flexDirection: 'column' }}>
      {/* <PageHeader title="Home" /> */}
      {loading && <Spinner />}
      {!loading && googleUser && (
        <>
          <Grid container spacing={2} sx={{ backgroundColor: '' }}>
            <Grid item xs={12} lg={4} sx={{ marginRight: 5 }}>
              <Box sx={{ paddingLeft: 2, paddingTop: 3, backgroundColor: '' }}>
                <Typography variant="h5" sx={{}}>
                  Welcome, {googleUser.given_name} !
                </Typography>
                {/* <RoadmapSteps /> */}
                <img
                  src={coder}
                  alt="Logo"
                  className="rotate-left"
                  style={{
                    width: '30vw',
                    padding: 30,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                />
                <QuoteCard />
              </Box>
            </Grid>
            <Grid item xs={12} lg={4} sx={{ backgroundColor: '' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  height: '100vh',
                  paddingTop: 3,
                  backgroundColor: '',
                }}
              >
                <Typography variant="h5" sx={{ backgroundColor: '' }}>
                  Upcoming Events & Meetings
                </Typography>

                {calendarEvents && calendarEvents.length > 0 && <EventsCarousel events={calendarEvents} />}
                {(!calendarEvents || calendarEvents.length <= 0) && (
                  <Typography sx={{ py: 2, backgroundColor: '' }}>No upcoming events</Typography>
                )}
                <DateCalendarServerRequest
                  events={calendarEvents}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                    backgroundColor: '',
                    width: '30vw',
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </Stack>
  );
}
