export const onboardingSteps = [
  {
    step: 1,
    content: (mentee: string) => `Hi ${mentee}!

Welcome to your first day in the mentorship program!

Your most important task at the start is to understand the “how-to” in Milestone 1.

Step 1: Go to “Targets” and select the topic marked as “In Progress.”

Your Onboarding Daily Tasks:
	•	On weekdays: Aim to solve at least 3 questions per day.
	•	On weekends: Aim for at least 5 questions each day.

Here’s how to approach each question:
	1.	Start with Pen and Paper:
Before typing anything, write down the problem by hand. Break it down into smaller sub-problems and play around with small examples that you can manually test. The goal is to understand the flow of the problem fully.
	2.	Prove it by Hand:
Once you think you have a solution, walk through the algorithm manually (as if you didn’t have a compiler). Write out comments, draw diagrams, or use a pen and paper to simulate each step and verify your logic.
	3.	Write Code with Confidence:
When you’re sure about your approach, proceed to write the algorithm in LeetCode. Avoid using IDEs, code editors, or debuggers—this exercise is to build your mental problem-solving skills.
	4.	Unsure About a Solution?
	•	For Easy-level questions, look at the official solution if you’re stuck. Study it deeply and play with examples until you fully understand the concept. You can use additional resources like YouTube, Google, or ChatGPT to aid your understanding.
	•	Important: If you start coding and get stuck halfway through, it’s a sign that you didn’t understand the problem as well as you thought. Take a step back and review the question again.
	5.	Don’t Rush the Process:
Don’t move on to the next problem until you are 100% confident you understand the current one. Mastery is more important than speed at this stage.
	6.	Time Management:
If you’re stuck on a problem for 60-90 minutes, it’s okay to submit an empty response so LeetCode can mark it as failed. We don’t want to linger too long on one problem, but if you feel like you’re making progress, feel free to push to 90-120 minutes.

If you’re ever feeling lost, don’t hesitate to ask questions or seek help! You can post in the #leetcode-grind channel or send me a direct message anytime.

Good luck, and remember: Every question is a step towards growth!

Best regards,
Your Favourite Mentor
`,
  },
  {
    step: 2,
    content: (mentee: string) => `Hi ${mentee}!
    
Two days in! It's slowly getting more and more exciting!

Let's dive into the "solved" vs "failed" in the Tracker and Targets:
    1. Only questions that passed 100% from the FIRST submission go to "solved"
    2. We'll come back to the "failed" but NEVER to the solved
    3. Even if you solved a question but you are hesitant, feel free to submit a wrong solution to mark it as "failed"
    
The rule of thumb is - if you'd get it right in 6 months time, it's "solved".

We want to be certain that those are only questions you are fully comfortable with. Within the span of your
preparation, we will iterate over the "failed" questions, but never to the "solved" ones.

I hope this shed some light on the topic! Feel free to reach out for any questions!

Good luck,
Your Favourite Mentor`,
  },
  {
    step: 3,
    content: (mentee: string) => `Hi ${mentee}!\n
    Three days in! How quickly the time passes by! You could have done nothing in those 3 days, too, instead of
taking action to improve your life! 

Today, let's talk about your approach and why we don't use IDEs or don't run code:
  a) Negatives of using an IDE and running code:
    1. By using an IDE, you TRAIN your intuition and attention that it's ok to make idle mistakes
    2. The IDE builds the FALSE impression that you know more than you actually do (auto-complete, error detection, etc)
    3. Running the code 7 times in 2 minutes contributes to lack of attention
  b) Positives of NOT using an IDE and running code:
    1. You build good coding technique - no off-by-one mistakes, variable naming, cleanliness of code and structure
    2. Debugging in your mind or paper allows you to intrinsically understand the problem
    3. Build intuition of right/wrong code just by visually looking at it
    
Those are only a handful of the benefits! You'll be transformed in a month time! I look forward
to see your progress! 

Reach out if you have any questions! 

Good luck,
Your Favourite Mentor
    `,
  },
  {
    step: 4,
    content: (mentee: string) => `Hi ${mentee}!\n
    Welcome to the second part of your onboarding!

It's time for your Assessment Level!

The Test will be sent to you today. Please, complete it within 3 days of receiving.

Here are the rules before opening it:
    1. Do NOT OPEN until you are ready to start!
    2. You'd need 90 minutes to complete the test - time it promptly!
    3. Do NOT use IDE, Google, ChatGPT, or any other helper tools!
    4. Do NOT RUN the code Anywhere! (NO IDEs!!!)
    5. Do NOT Seek any help!
    6. You can use pen and paper
    7. Once finished - download the test a .pdf and send it back to the same email.
    
Please, contact me for any questions! 

It's very important to obey these rules so your level
can be measure as accurately as possible! 

Good luck,
Your Favourite Mentor
    `,
  },
  {
    step: 5,
    content: (mentee: string) => `Hi ${mentee}!\n
    Yesterday, I sent you the Entry Level Test - make sure you've received it and have understood the rules!

Let's discuss the HOW-TO key takeaways again:
    There are Run and Submit options. You will ONLY use the SUBMIT one - it runs your code through the 
    entire set of tests! 
    1. Only use Submit button when you solve problem!
    2. If you don't get a success from the first submission, that's a "failed" question
    3. If you cannot figure out what's wrong with your problem, go ahead and review the solutions
    4. Even if you find your mistake, the problem still goes to "failed" so submit a wrong/empty solution

The HOW-TO is designed in a way to not only improve your learning, but also teach you skills that
will help you being a better coder at all. 

The main lessons:
    1. Know what you write - often, we go to the keyboard thinking and the result is trash. The problem
stems from the fact that we get attached to our trash and don't want to throw it away. 
    2. Play it in your head - you will gradually develop the skill to run small examples in your head
massively speeding up the development process! You will notice considerably less 'failed runs' of code
at work and during the program. You will start seeing other people's bugs from miles away!
    3. Learn all details - when you play the example in your head, you HAVE TO know what that function is doing
exactly - how do you play it around in your head!? That leads to learning all general functionalities and
behaviours very very well! 

It sounds good but it takes time. And is hard. But the results are worth it. 

Good luck,
Your Favourite Mentor
    `,
  },
  {
    step: 6,
    content: (mentee: string) => `Hi ${mentee}!\n
    Today and tomorrow, I want to give you some practical advice that helped me, friends, tens of mentees like you.

Lesson #1: When It Gets Tough!

It will get tough. And it will get tough very soon! Grinding a few weeks every day and not having massive results yet
is somewhat a hit. I've experienced this for years - when I was in the National Math Team, when I was playing poker 
professionally, when I started looking for internships, later with full-time work etc. 

We've all been through it. Well, we = those who pushed through and did not give up!

Pain and hardships are actually a good thing. Even though it feels bad. And because it feels bad, most people will
simply give up! That's why I love when it gets tough. Finding new people is tough, solving leetcode every day
is tough, loosing weight is tough, working every weekend for months is tough.

But each of those tough events, makes people give up. Hence, less and less and less competition for you. That's
why the reward on the top is Exponential! Going to bed at 1am with the alarm set for 6:40am after 15hours of work feels
amazing. I am smiling, I am happy how many people did give up today and could not beat me and now (at some point)
I will get all their reward. 

Be happy when it gets tough even if it hurts. 

Rule of thumb: If you feel emotional about the hardship/pain, set an alarm for 10minutes. Take those 10minutes
away WITHOUT ANY DISTRACTIONS - NO PHONE, NO LAPTOP, NO TALKING NO ANYONE! Then, come back. You will almost always
be ready to push through. Emotional pain fades away withing 5-10 minutes most of the time!

Good luck,
Your Favourite Mentor
    `,
  },
  {
    step: 7,
    content: (mentee: string) => `Hi ${mentee}!\n
    Time for lesson #2: Don't Rush It

In the beginning it's very easy to come motivated and start grinding. With time, the enthusiasm struggles as we
discussed yesterday. Then, it's very tempting to overlook stuff and start rush it up to spike some dopamine and 
get it going quicker. Then you fail miserably. 

Make sure that each step you make, every problem you solve, every page of a book you read, you've put the maximum
you can. 

Let's do the math:
    1) 20 days solving 10 problems poorly = 200 poorly solved problems, 150 needs to be re-solved again
    2) 20 days solving 6 problems well = 120 well understood problems, 40 needs to be re-solved again

In the second scenario, you end up with 80 learned problems, in the first one - 50. Not only you learn less, but
the frustration builds up. The time is ticking and you wish you did learn them well but now it's much harder to 
re-solve them. You end up in some anxiety/stress moment when you don't know what to do.

You'd better do less in high quality than much in poor quality. That's mentorship but also life advice. 

If you begin to rush it up - call me, message me, speak about it. It's better to stop it early, than late.

Good luck,
Your Favourite Mentor
    `,
  },
];
