import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import BoltSharpIcon from '@mui/icons-material/BoltSharp';
import DoneAllSharpIcon from '@mui/icons-material/DoneAllSharp';
import PlayCircleOutlineSharpIcon from '@mui/icons-material/PlayCircleOutlineSharp';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';
import { routes, themeColors, apiServerLink, localStorageKeys } from 'sitevars';
import { LeetcodeLevels, TestProps } from 'interfaces/interfaces';
import LockSharpIcon from '@mui/icons-material/LockSharp';
import TimerSharpIcon from '@mui/icons-material/TimerSharp';
import GradingIcon from '@mui/icons-material/Grading';
import {
  Box,
  Button,
  Fade,
  Modal,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  useTheme,
} from '@mui/material';
import { getDifficultyIconColor } from 'utils/Helpers';
import axios from 'axios';
import { useState, useEffect } from 'react';
import TestResultButtonModal from './buttons/TestResultButtonModal';

const style = {
  position: 'absolute' as const,
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  padding: 5,
  bgcolor: 'background.paper',
  boxShadow: 10,
  paddingBottom: 5,
};

interface TestCardProps {
  data: TestProps;
}

export function TestCard({ data }: TestCardProps): React.JSX.Element {
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [option, setOption] = useState<string>('');
  const [showOptionMenu, setShowOptionMenu] = useState<boolean>(true);
  const theme = useTheme();

  useEffect(() => {
    const savedOption = localStorage.getItem(localStorageKeys.testLanguage);
    if (savedOption) {
      if (localStorage.getItem(localStorageKeys.isTestSessionActive)) {
        setOption(savedOption);
        // setShowOptionMenu(false);
      } else {
        localStorage.removeItem(localStorageKeys.testLanguage);
        localStorage.removeItem(localStorageKeys.testsData);
      }
    }
  }, []);

  const difficultyEnum: LeetcodeLevels = data.difficulty as LeetcodeLevels;
  const difficultyColor = getDifficultyIconColor(difficultyEnum);

  const getIconColor = (tests: number) => {
    if (tests === 0) return themeColors.muiPrimaryBlue; // unlocked
    if (tests === 1) return themeColors.lightGreen; // done
    if (tests === -1) return themeColors.dimLightGrey; // locked
    return 'lightgrey';
  };

  const getPointer = (tests: number) => {
    if (tests === 0) return 'pointer'; // unlocked
    if (tests === 1) return 'not-allowed'; // done
    if (tests === -1) return 'not-allowed'; // locked
    return 'lightgrey';
  };

  const handleAgree = async (selectedOption: string) => {
    try {
      const response = await axios.get(
        `${apiServerLink}/api/test_template?difficulty=${data.difficulty}&topic=${data.topicTags}&language=${selectedOption}`
      );
      const initialCode = response.data.initialCode;
      navigate(routes.liveTest, {
        state: { initialCode, time: data.time, difficulty: data.difficulty, topic: data.topicTags },
      });
    } catch (error) {
      console.error('Error fetching challenge details:', error);
    }
  };

  // const handleReadOnlyTest = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${apiServerLink}/api/test_template?difficulty=${data.difficulty}&topic=${data.topicTags}&language=${selectedOption}`
  //     );
  //     const initialCode = response.data.initialCode;
  //     navigate(routes.liveTest, {
  //       state: { initialCode, time: data.time, difficulty: data.difficulty, topic: data.topicTags },
  //     });
  //   } catch (error) {
  //     console.error('Error fetching challenge details:', error);
  //   }
  // };

  const handleOpen = async () => {
    if (data.tests === 0) {
      const savedOption = localStorage.getItem(localStorageKeys.testLanguage);
      if (savedOption) {
        await handleAgree(savedOption);
      } else {
        setModalIsOpen(true);
      }
    }
    // if (data.tests === 1) {
    //   const readOnly = localStorage.getItem(localStorageKeys.readOnly);
    //   if (readOnly) {
    //     handleReadOnlyTest()
    //   }
    // }
  };

  const handleClose = () => {
    setModalIsOpen(false);
  };

  const handleChange = (event: SelectChangeEvent) => {
    const selectedOption = event.target.value as string;
    setOption(selectedOption);
    localStorage.setItem(localStorageKeys.testLanguage, selectedOption);
    // setShowOptionMenu(false);
  };

  return (
    <div>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          cursor: getPointer(data.tests),
          height: '100%',
        }}
        onClick={handleOpen}
      >
        <CardContent
          sx={{
            flex: '1 1 auto',
            pointer: 'cursor',
            transition: 'background-color 0.3s ease',
            '&:hover': {
              backgroundColor: getIconColor(data.tests),
            },
          }}
        >
          <Stack spacing={2}>
            <Stack direction="row" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
              <Stack spacing={1}>
                <Typography color="text.secondary" variant="overline">
                  Explore
                </Typography>
                <Typography variant="h5">{data.title}</Typography>
              </Stack>
              <Avatar
                sx={{
                  backgroundColor: getIconColor(data.tests),
                  height: '36px',
                  width: '36px',
                }}
              >
                {data.tests === -1 && <LockSharpIcon />}
                {data.tests === 1 && <DoneAllSharpIcon />}
                {data.tests === 0 && <PlayCircleOutlineSharpIcon />}
              </Avatar>
            </Stack>
          </Stack>
        </CardContent>

        <Divider />
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center', justifyContent: 'space-between', p: 2 }}>
          <Stack sx={{ alignItems: 'center' }} direction="row" spacing={1}>
            <BoltSharpIcon sx={{ fontSize: 20, color: themeColors.dimGrey }} />
            <Typography color="text.secondary" display="inline" variant="body2">
              Difficulty:
            </Typography>
            <Box
              sx={{
                backgroundColor: difficultyColor,
                borderRadius: '8px',
                padding: '1px',
                paddingX: '15px',
                display: 'inline-block',
              }}
            >
              <Typography variant="body1" sx={{ color: 'white' }}>
                {data.difficulty}
              </Typography>
            </Box>
          </Stack>
          <Stack sx={{ alignItems: 'center' }} direction="row" spacing={1}>
            <TimerSharpIcon sx={{ fontSize: 20, color: themeColors.dimGrey }} />
            <Typography color="text.secondary" display="inline" variant="body2">
              Time: {data.time} minutes
            </Typography>
          </Stack>
        </Stack>
        {data.tests === -1 && (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
              backgroundColor: theme.palette.primary.light,
              fontSize: 14,
            }}
          >
            <Button
              disableElevation
              sx={{
                textTransform: 'none',
                color: theme.palette.text.primary,
                width: '100%',
                padding: 0,
                margin: 0,
                fontSize: 14,
                cursor: getPointer(data.tests),
                transition: 'none',
                '&:hover': {
                  backgroundColor: theme.palette.primary.light,
                  color: theme.palette.text.primary,
                },
              }}
            >
              Pending
            </Button>
          </Typography>
        )}

        {data.tests === 0 && (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
              backgroundColor: themeColors.muiPrimaryBlue,
              fontSize: 14,
            }}
          >
            <Button
              disableElevation
              sx={{
                textTransform: 'none',
                color: theme.palette.primary.light,
                width: '100%',
                padding: 0,
                margin: 0,
                fontSize: 14,
                cursor: getPointer(data.tests),
                transition: 'none',
                '&:hover': {
                  backgroundColor: themeColors.muiLightBlue,
                  color: themeColors.muiPrimaryBlue,
                },
              }}
            >
              Start Now
            </Button>
          </Typography>
        )}

        {data.tests === 1 && (
          <Stack sx={{ alignItems: 'center' }} direction="row" spacing={1}>
            {data.results === null ? (
              <>
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    width: '100%',
                    backgroundColor: theme.palette.primary.light,
                    fontSize: 14,
                  }}
                >
                  <Button
                    disableElevation
                    sx={{
                      textTransform: 'none',
                      color: theme.palette.text.primary,
                      width: '100%',
                      padding: 0,
                      margin: 0,
                      fontSize: 14,
                      cursor: getPointer(data.tests),
                      transition: 'none',
                      '&:hover': {
                        backgroundColor: theme.palette.primary.light,
                        color: theme.palette.text.primary,
                      },
                    }}
                  >
                    Evaluating...
                  </Button>
                </Typography>
              </>
            ) : (
              <TestResultButtonModal feedback={data.feedback} testTopic={data.title} grade={data.results} />
            )}
          </Stack>
        )}
      </Card>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Modal
          aria-labelledby="transition-modal-title-avatar"
          aria-describedby="transition-modal-description-avatar"
          open={modalIsOpen}
          onClose={handleClose}
          closeAfterTransition
        >
          <Fade in={modalIsOpen}>
            <Box sx={style}>
              <Typography
                sx={{
                  fontWeight: 300,
                  fontSize: 20,
                  borderBottom: '1px solid #ccc',
                  paddingBottom: 1,
                  marginBottom: 3,
                }}
              >
                Rules
              </Typography>
              {showOptionMenu && (
                <FormControl fullWidth sx={{ marginBottom: 2 }}>
                  <InputLabel id="select-label">Select Language </InputLabel>
                  <Select labelId="select-label" value={option} label="Select Language" onChange={handleChange}>
                    <MenuItem value={'python'}>Python</MenuItem>
                    <MenuItem value={'java'}>Java</MenuItem>
                    <MenuItem value={'csharp'}>C#</MenuItem>
                    <MenuItem value={'javascript'}>JavaScript</MenuItem>
                  </Select>
                </FormControl>
              )}
              <Typography sx={{ fontWeight: 300 }}>- Set an alarm for {data.time} minutes max</Typography>
              <Typography sx={{ fontWeight: 300 }}>- Do NOT run the code in a console, IDE, or other</Typography>
              <Typography sx={{ fontWeight: 300 }}>- Do NOT seek any external help</Typography>
              <Typography sx={{ paddingTop: 2, paddingBottom: 2, fontWeight: 300 }}>
                Failing to obey the rules above might result in a wrong judgement and hence suboptimal further process.
              </Typography>

              <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2, marginBottom: 2 }}>
                <Button
                  sx={{
                    marginRight: 2,
                    textTransform: 'none',
                    backgroundColor: themeColors.deepGreen,
                    '&:hover': {
                      backgroundColor: themeColors.lightGreen,
                    },
                  }}
                  variant="contained"
                  onClick={() => handleAgree(option)}
                >
                  Agree
                </Button>
                <Button
                  sx={{
                    textTransform: 'none',
                    backgroundColor: themeColors.cherryRedBright,
                    '&:hover': {
                      backgroundColor: themeColors.lightRed,
                    },
                  }}
                  variant="contained"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </div>
    </div>
  );
}
