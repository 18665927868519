import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { themeColors } from 'sitevars';
import { getDifficultyIconColor } from 'utils/Helpers';
import { LeetcodeLevels, TopicTag } from 'interfaces/interfaces';
import { useTheme } from '@mui/material';

const Wrapper = styled('div')({
  position: 'relative',
  width: '75vw',
  height: '75vh',
  overflow: 'auto',
  margin: 'auto',
});

const TableHeadCellStyled = styled(TableCell)({
  fontWeight: 'bold',
  textAlign: 'center',
  padding: '10px 5px',
});

const TableRowStyled = styled(TableRow)({
  '&:nth-of-type(odd)': {
    backgroundColor: '#f9f9f9',
  },
});

const TableCellStyled = styled(TableCell)({
  padding: '10px 15px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '150px',
});

const StatusDivStyled = styled('div')<{ solved: boolean }>(({ solved }) => ({
  backgroundColor: solved ? themeColors.lightGreen : themeColors.lightRed,
  color: themeColors.grey600,
  borderRadius: '8px',
  padding: '1px 8px',
  display: 'inline-block',
  textAlign: 'center',
}));

export const DifficultyDivStyled = styled('div')<{ difficulty: string }>(({ difficulty }) => ({
  backgroundColor: getDifficultyIconColor(difficulty as LeetcodeLevels),
  color: themeColors.grey600,
  borderRadius: '8px',
  padding: '1px 8px',
  display: 'inline-block',
  textAlign: 'center',
}));

interface DataTableProps {
  data: {
    date: number;
    title: string;
    solved: boolean;
    problem_link: string;
    difficulty: string;
    topicTags?: string;
    acRate: number;
    submissions_count: number;
  }[];
}

const formatDate = (timestamp: number) => {
  const date = new Date(timestamp);
  return date.toISOString().split('T')[0];
};

const groupDataByDate = (data: DataTableProps['data']) => {
  if (!Array.isArray(data)) {
    return {};
  }

  const groupedData = data.reduce((acc, row) => {
    const formattedDate = formatDate(row.date);
    if (!acc[formattedDate]) {
      acc[formattedDate] = [];
    }
    acc[formattedDate].push(row);
    return acc;
  }, {} as Record<string, DataTableProps['data']>);

  const sortedGroupedData = Object.keys(groupedData)
    .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
    .reduce((acc, date) => {
      acc[date] = groupedData[date];
      return acc;
    }, {} as Record<string, DataTableProps['data']>);

  return sortedGroupedData;
};

const DataTable: React.FC<DataTableProps> = ({ data }) => {
  const groupedData = groupDataByDate(data);
  const theme = useTheme();

  return (
    <Wrapper>
      <TableContainer component={Paper} style={{ maxWidth: '100%', maxHeight: '100%' }}>
        {groupedData && (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableHeadCellStyled>Date</TableHeadCellStyled>
                <TableHeadCellStyled>Count</TableHeadCellStyled>
                <TableHeadCellStyled>Title</TableHeadCellStyled>
                <TableHeadCellStyled>Status</TableHeadCellStyled>
                <TableHeadCellStyled>Difficulty</TableHeadCellStyled>
                <TableHeadCellStyled>Tags</TableHeadCellStyled>
                <TableHeadCellStyled>AC Rate</TableHeadCellStyled>
                <TableHeadCellStyled>Attempts</TableHeadCellStyled>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(groupedData).map(([date, rows], dateIndex) => (
                <React.Fragment key={date}>
                  {rows.map((row, rowIndex) => {
                    const isEvenRow = (dateIndex + rowIndex) % 2 === 0;
                    const backgroundColor = isEvenRow
                      ? theme.palette.background.default
                      : theme.palette.background.paper;

                    return (
                      <TableRowStyled key={row.title} style={{ backgroundColor }}>
                        {rowIndex === 0 && (
                          <>
                            <TableCellStyled rowSpan={rows.length} style={{ backgroundColor: '' }}>
                              {date}
                            </TableCellStyled>
                            <TableCellStyled
                              rowSpan={rows.length}
                              style={{ backgroundColor: '', textAlign: 'center', verticalAlign: 'middle' }}
                            >
                              {rows.length}
                            </TableCellStyled>
                          </>
                        )}
                        <TableCellStyled>
                          <a
                            href={row.problem_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: theme.palette.text.secondary }}
                          >
                            {row.title}
                          </a>
                        </TableCellStyled>
                        <TableCellStyled style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                          <StatusDivStyled solved={row.solved}>{row.solved ? 'solved' : 'failed'}</StatusDivStyled>
                        </TableCellStyled>
                        <TableCellStyled style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                          <DifficultyDivStyled difficulty={row.difficulty.toLowerCase()}>
                            {row.difficulty.toLowerCase()}
                          </DifficultyDivStyled>
                        </TableCellStyled>
                        <TableCellStyled style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                          {(() => {
                            try {
                              const topicTags = JSON.parse(row.topicTags || '[]') as TopicTag[];
                              if (Array.isArray(topicTags)) {
                                return topicTags.map((tag, index) => (
                                  <React.Fragment key={tag.id}>
                                    {tag.name}
                                    {index !== topicTags.length - 1 && ', '}
                                  </React.Fragment>
                                ));
                              } else {
                                return 'N/A';
                              }
                            } catch (error) {
                              console.error('Error parsing topic tags:', error);
                              return 'N/A';
                            }
                          })()}
                        </TableCellStyled>

                        <TableCellStyled style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                          {row.acRate.toFixed(2)}%
                        </TableCellStyled>
                        <TableCellStyled style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                          {row.submissions_count}
                        </TableCellStyled>
                      </TableRowStyled>
                    );
                  })}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </Wrapper>
  );
};

export default DataTable;
