import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { FormControl, InputLabel, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { localStorageKeys, themeColors } from 'sitevars';
import SettingsSharpIcon from '@mui/icons-material/SettingsSharp';

const style = {
  position: 'absolute' as const,
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30vw',
  maxHeight: '80vh',
  padding: 5,
  bgcolor: 'background.paper',
  boxShadow: 10,
  overflowY: 'auto',
};

interface IdeSettingsModalProps {
  fontSize: number;
  setFontSize: any;
}

export default function IdeSettingsModal({ fontSize, setFontSize }: IdeSettingsModalProps): React.JSX.Element {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event: any) => {
    const selectedFontSize = event.target.value;
    setFontSize(selectedFontSize);
    localStorage.setItem(localStorageKeys.ideFontSize, selectedFontSize);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <>
        <Tooltip title="Settings">
          <SettingsSharpIcon sx={{ color: themeColors.lightGrey, fontSize: 20 }} onClick={handleOpen} />
        </Tooltip>

        <Modal
          aria-labelledby="transition-modal-title-avatar"
          aria-describedby="transition-modal-description-avatar"
          open={open}
          onClose={handleClose}
          closeAfterTransition
        >
          <Fade in={open}>
            <Box sx={style}>
              <Typography
                sx={{
                  fontWeight: 300,
                  fontSize: 20,
                  borderBottom: '1px solid #ccc',
                  paddingBottom: 1,
                }}
              >
                Code Editor Settings
              </Typography>
              <div style={{ padding: 10 }} />

              <FormControl fullWidth>
                <InputLabel id="number-dropdown-label">Select Font Size</InputLabel>
                <Select
                  labelId="number-dropdown-label"
                  value={fontSize}
                  label="Select Font Size"
                  onChange={handleChange}
                >
                  {Array.from({ length: 12 }, (_, i) => i + 10).map((number) => (
                    <MenuItem key={number} value={number}>
                      {number}px
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Fade>
        </Modal>
      </>
    </div>
  );
}
