import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TargetCard from 'components/TargetCard';
import { AppContext } from 'context/context';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { LeetcodeLevels, Problem, TopicStatus } from 'interfaces/interfaces';
import {
  countTopicStatus,
  createTopicStatusMap,
  getCurrentTopic,
  getPreviousAndNextTopic,
  sortDataBySolveStatusAndAcRate,
  TopicNavigationResult,
  transformRawData,
} from 'utils/ProblemHelpers';
import { localStorageKeys, themeColors, apiServerLink } from 'sitevars';
import Spinner from 'components/common/Spinner';
import { PageHeader } from 'components/common/PageHeader';
import { LinearProgress } from '@mui/material';
import PieChartVisualsization, { ChartProps } from 'components/charts/PieChartVisualsization';
import { TopicTrackerSectionTitle } from 'components/common/TopicTrackerSectionTitle';
import BarChartVisualization from 'components/charts/BarChartVisualization';
import { PrevCurrNextCard } from 'components/charts/PrevCurrNextCard';
import { getUserId } from 'utils/Helpers';

function Targets() {
  const { state, dispatch } = React.useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [topicsStatusData, setTopicsStatusData] = useState<TopicStatus[]>([]);
  const [totalTargets, setTotalTargets] = useState<number>(0);
  const [completedCount, setCompletedCount] = useState<number>(0);
  const [inProgressCount, setInProgressCount] = useState<number>(0);
  const [lockedCount, setLockedCount] = useState<number>(0);
  const [trackerChartProps, setTrackerChartProps] = useState<ChartProps[]>([]);
  const [prevCurrNextTopics, setPrevCurrNextTopics] = useState<TopicNavigationResult>();

  useEffect(() => {
    const userId = getUserId(state);
    if (userId) {
      if (
        localStorage.getItem(localStorageKeys.tasksData) === '' ||
        localStorage.getItem(localStorageKeys.tasksData) === null ||
        localStorage.getItem(localStorageKeys.tasksData) === undefined
      ) {
        axios
          .get(`${apiServerLink}/lc/targets?user_id=${userId}`)
          .then((response) => {
            const transformedData = response.data;

            const sortedData = sortDataBySolveStatusAndAcRate(transformedData);
            const topicsData = createTopicStatusMap(transformedData);
            setTopicsStatusData(topicsData);

            const counts = countTopicStatus(topicsData);
            setTotalTargets(counts.totalTargets);
            setCompletedCount(counts.completedCount);
            setInProgressCount(counts.inProgressCount);
            setLockedCount(counts.lockedCount);
            const currentTopic = getCurrentTopic(topicsData);
            if (currentTopic != null) {
              const prevCurrNext = getPreviousAndNextTopic(currentTopic.difficulty, currentTopic.topic);
              setPrevCurrNextTopics(prevCurrNext);
            }
            const trackerChartProps: ChartProps[] = [
              {
                id: 1,
                label: `Completed (${counts.completedCount})`,
                value: counts.completedCount,
                color: themeColors.deepGreen,
              },
              {
                id: 2,
                label: `In Progress (${counts.inProgressCount})`,
                value: counts.inProgressCount,
                color: themeColors.dimBlue,
              },
              {
                id: 3,
                label: `Total (${counts.totalTargets})`,
                value: counts.totalTargets,
                color: themeColors.dimLightGrey,
              },
            ];
            setTrackerChartProps(trackerChartProps);

            // localStorage.setItem(localStorageKeys.tasksData, JSON.stringify(sortedData));

            setLoading(false);
          })
          .catch((error) => {
            console.error('There was an error fetching the data!', error);
            setLoading(false);
          });
      } else {
        const storedData = localStorage.getItem(localStorageKeys.tasksData);
        if (storedData) {
          const parsedData = JSON.parse(storedData);
          const topicsStatusData = createTopicStatusMap(parsedData);
          setTopicsStatusData(topicsStatusData);

          const counts = countTopicStatus(topicsStatusData);
          setTotalTargets(counts.totalTargets);
          setCompletedCount(counts.completedCount);
          setInProgressCount(counts.inProgressCount);
          setLockedCount(counts.lockedCount);

          const currentTopic = getCurrentTopic(topicsStatusData);
          if (currentTopic != null) {
            const prevCurrNext = getPreviousAndNextTopic(currentTopic.difficulty, currentTopic.topic);
            setPrevCurrNextTopics(prevCurrNext);
          }

          const trackerChartProps: ChartProps[] = [
            {
              id: 1,
              label: `Completed (${counts.completedCount})`,
              value: counts.completedCount,
              color: themeColors.deepGreen,
            },
            {
              id: 2,
              label: `In Progress (${counts.inProgressCount})`,
              value: counts.inProgressCount,
              color: themeColors.dimBlue,
            },
            {
              id: 3,
              label: `Total (${counts.totalTargets})`,
              value: counts.totalTargets,
              color: themeColors.dimLightGrey,
            },
          ];
          setTrackerChartProps(trackerChartProps);

          setLoading(false);
        } else {
          console.error("Couldn't get data from local storage properly");
        }
      }
    } else {
      setLoading(false);
      setError(true);
    }
  }, []);

  return (
    <div>
      <Stack spacing={1} sx={{ backgroundColor: '', paddingBottom: 20 }}>
        <PageHeader title="Tracker Targets" />

        {loading && <Spinner />}
        {!loading && error && (
          <Typography>
            Sorry, something went wrong. Please close the app and try to login again. If the issue persists, please
            contact the administrator.
          </Typography>
        )}

        {!loading && !error && (
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <TopicTrackerSectionTitle title="Targets" />

              <Grid container spacing={2}>
                {topicsStatusData?.map((topic) => (
                  <Grid key={`${topic.topic}-${topic.difficulty}`} item xs={12}>
                    <TargetCard topicStatus={topic} />
                  </Grid>
                ))}
              </Grid>
            </Grid>

            {trackerChartProps && trackerChartProps.length > 0 && (
              <Grid item xs={12} lg={6} sx={{ backgroundColor: '' }}>
                <TopicTrackerSectionTitle title="Statistics" />
                {prevCurrNextTopics && prevCurrNextTopics !== undefined && (
                  <PrevCurrNextCard data={prevCurrNextTopics} />
                )}
                <PieChartVisualsization chartProps={trackerChartProps} title="Overview" />
              </Grid>
            )}
          </Grid>
        )}
      </Stack>
    </div>
  );
}

export default Targets;
