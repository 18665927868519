import React, { useState, useEffect, useContext } from 'react';
import './App.css';
import './Discord.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AppContext, AppProvider } from './context/context';
import GetStarted from 'pages/GetStarted';
import Home from 'pages/Home';
import Tracker from 'pages/Tracker';
import Targets from 'pages/Targets';
import Tests from 'pages/Tests';
import Sidebar from 'components/navigation/Sidebar';
import TopicTracker from 'pages/TopicTracker';
import { localStorageKeys, routes } from 'sitevars';
import toggleLogo from 'assets/topcodingToggle.png';
import CodeEditor from 'components/CodeEditor';
import PageNotFound from 'pages/PageNotFound';
import Problems from 'pages/Problems';
import AdminTestGrading from 'pages-admin/AdminTestGrading';
import Admin from 'pages-admin/Admin';
import ProblemEditor from 'components/ProblemEditor';
import { lightTheme, darkTheme } from './theme';
import { ThemeProvider as MuiThemeProvider, CssBaseline } from '@mui/material';
import { ThemeModeType, useTheme } from 'context/GlobalThemeProvider';
import { GlobalStyles } from '@mui/material';
import Onboarding from 'pages/Onboarding';
import JobsTracker from 'pages/JobsTracker';
import JobCard from 'components/JobCard';

function App() {
  const location = useLocation();
  const [isWidgetVisible, setWidgetVisible] = useState(false);
  const [currentTheme, setCurrentTheme] = useState(darkTheme);
  const { state, dispatch } = useContext(AppContext);
  const { themeMode } = useTheme();

  const toggleWidgetVisibility = () => {
    setWidgetVisible(!isWidgetVisible);
  };

  useEffect(() => {
    if (isWidgetVisible) {
      const script = document.createElement('script');
      script.src = 'https://cdn.jsdelivr.net/npm/@widgetbot/html-embed';
      script.async = true;
      script.id = 'widgetbot-script';
      document.body.appendChild(script);

      return () => {
        const widgetScript = document.getElementById('widgetbot-script');
        if (widgetScript) {
          widgetScript.remove();
        }
      };
    }
  }, [isWidgetVisible]);

  const isUserAuthorized = JSON.parse(localStorage.getItem(localStorageKeys.isUserAuthorized) || 'false');

  useEffect(() => {
    const theme = themeMode === ThemeModeType.DARK ? darkTheme : lightTheme;
    setCurrentTheme(theme);
  }, [themeMode]);

  return (
    <React.Fragment>
      <MuiThemeProvider theme={currentTheme}>
        <CssBaseline />
        <AppProvider>
          <GlobalStyles
            styles={{
              '::-webkit-scrollbar': {
                height: '4px' /* Thinner height for horizontal scrollbars */,
                width: '4px' /* Thinner width for vertical scrollbars */,
              },
              '::-webkit-scrollbar-thumb': {
                backgroundColor:
                  themeMode === ThemeModeType.DARK
                    ? '#888'
                    : '#ccc' /* Darker color in dark mode, lighter in light mode */,
                borderRadius: '10px',
              },
              '::-webkit-scrollbar-track': {
                backgroundColor:
                  themeMode === ThemeModeType.DARK
                    ? '#333'
                    : '#f1f1f1' /* Darker track in dark mode, lighter in light mode */,
              },
              '*': {
                scrollbarWidth: 'thin' /* Makes scrollbar thinner in Firefox */,
                scrollbarColor: `${themeMode === ThemeModeType.DARK ? '#888' : '#ccc'} ${
                  themeMode === ThemeModeType.DARK ? '#333' : '#f1f1f1'
                }` /* Scrollbar and track color in Firefox */,
              },
            }}
          />
          <div style={{ display: 'flex', height: '100vh', backgroundColor: '' }}>
            {isUserAuthorized && location.pathname !== '/' && (
              <div style={{ position: 'fixed', height: '100vh', backgroundColor: '' }}>
                <Sidebar />
              </div>
            )}

            <div
              style={{
                marginLeft: isUserAuthorized && location.pathname !== '/' ? '220px' : '0px', // Adjust margin based on authorization
                marginTop: '10px',
                marginRight: '50px',
                display: 'flex',
                width: '100vw',
              }}
            >
              <Routes>
                <Route path={routes.root} element={<GetStarted />} />
                {isUserAuthorized ? (
                  <>
                    <Route path={routes.home} element={<Home />} />
                    <Route path={routes.tracker} element={<Tracker />} />
                    <Route path={routes.tests} element={<Tests />} />
                    <Route path={routes.targets} element={<Targets />} />
                    <Route path={`${routes.targets}/:topic/:difficulty`} element={<TopicTracker topicData={null} />} />
                    <Route path={routes.liveTest} element={<CodeEditor />} />
                    <Route path={routes.problems} element={<Problems />} />
                    <Route path={`${routes.problems}/:question_id/:title/:difficulty`} element={<ProblemEditor />} />
                    <Route path={routes.oboarding} element={<Onboarding />} />
                    <Route path={routes.jobsTracker} element={<JobsTracker />} />
                    <Route path={`${routes.jobsTracker}/:job_id`} element={<JobCard />} />

                    <Route path="*" element={<PageNotFound />} />

                    {/* admin routes below */}
                    <Route path={routes.admin} element={<Admin />} />
                    <Route path={routes.adminTestGrading} element={<AdminTestGrading />} />
                  </>
                ) : (
                  <Route path="*" element={<PageNotFound />} />
                )}
              </Routes>

              {isUserAuthorized && (
                <>
                  <button className="chat-toggle-button" onClick={toggleWidgetVisibility}>
                    {isWidgetVisible ? 'X' : <img src={toggleLogo} alt="Chat" className="chat-icon" />}
                  </button>
                  <div className={`chat-widget ${isWidgetVisible ? 'visible' : 'hidden'}`}>
                    <widgetbot
                      server="1205237203219779644"
                      channel="1208834866397970462"
                      width="100%"
                      height="100%"
                    ></widgetbot>
                  </div>
                </>
              )}
            </div>
          </div>
        </AppProvider>
      </MuiThemeProvider>
    </React.Fragment>
  );
}

export default App;
