import PortalSidebar from './PortalSidebar'
import MenuSidebar from './MenuSidebar'

export default function Sidebar() {
  return (
    <div style={{ flex: 1, display: 'flex' }}>
      <MenuSidebar />
      <PortalSidebar />
    </div>
  )
}
