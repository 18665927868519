import * as React from 'react';
import TargetCard from 'components/TargetCard';
import PieChartVisualsization, { ChartProps } from 'components/charts/PieChartVisualsization';
import { AppContext } from 'context/context';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { LeetcodeLevels, Problem, TopicStatus } from 'interfaces/interfaces';
import {
  countFailedProblems,
  createTopicStatusMap,
  filterDataByTopicAndDifficulty,
  transformRawData,
} from 'utils/ProblemHelpers';
import BoltSharpIcon from '@mui/icons-material/BoltSharp';
import { localStorageKeys, themeColors } from 'sitevars';
import { useParams } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Box,
  Stack,
  Grid,
} from '@mui/material';
import { TopicSummaryCard } from './TopicSummaryCard';
import TopicSharpIcon from '@mui/icons-material/TopicSharp';
import { getDifficultyIconColor } from 'utils/Helpers';

export interface TopicSummaryCardProps {
  difficulty?: LeetcodeLevels;
  topic?: string;
  progress?: number;
}

export function TopicSummary({ difficulty, topic, progress }: TopicSummaryCardProps): React.JSX.Element {
  const difficultyIconColor = getDifficultyIconColor(difficulty);
  const currentProgress = progress && progress > 100 ? 100 : progress;

  return (
    <Stack direction="row" spacing={2} sx={{ alignItems: 'center', justifyContent: 'flex-start', p: 2 }}>
      <TopicSummaryCard
        title="topic"
        subTitle={topic}
        sx={{ height: '20vh' }}
        icon={<TopicSharpIcon />}
        iconColor={themeColors.muiPrimaryBlue}
      />
      <TopicSummaryCard
        title="difficulty"
        subTitle={difficulty}
        sx={{ height: '20vh' }}
        icon={<BoltSharpIcon />}
        iconColor={difficultyIconColor}
      />
      <TopicSummaryCard
        title="task progress"
        subTitle={`${currentProgress}%`}
        progressValue={currentProgress}
        showProgressBar={true}
        sx={{ height: '20vh' }}
      />
    </Stack>
  );
}
