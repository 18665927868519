import { ChartProps } from 'components/charts/PieChartVisualsization';
import { LeetcodeLevels, TestProps, TopicStatusTypes, UserData } from 'interfaces/interfaces';
import { localStorageKeys, themeColors } from 'sitevars';

export const getDifficultyIconColor = (difficulty?: LeetcodeLevels): string => {
  switch (difficulty) {
    case LeetcodeLevels.EASY:
      return themeColors.lightGreen;
    case LeetcodeLevels.MEDIUM:
      return themeColors.lightYellow;
    case LeetcodeLevels.HARD:
      return themeColors.lightRed;
    default:
      return themeColors.lightGrey;
  }
};

export const mapTopicStatus = (status: TopicStatusTypes): string => {
  switch (status) {
    case TopicStatusTypes.CURRENT:
      return 'In Progress';
    case TopicStatusTypes.FINISHED:
      return 'Completed';
    case TopicStatusTypes.FUTURE:
      return 'Locked';
    default:
      return 'Locked'; // Handle any unexpected values gracefully
  }
};

export const mapTopicStatusToPointer = (status: TopicStatusTypes): string => {
  switch (status) {
    case TopicStatusTypes.CURRENT:
      return 'pointer';
    case TopicStatusTypes.FINISHED:
      return 'pointer';
    case TopicStatusTypes.FUTURE:
      return 'not-allowed';
    default:
      return 'Unknown'; // Handle any unexpected values gracefully
  }
};

export const getTopicStatusColor = (status?: TopicStatusTypes): string => {
  switch (status) {
    case TopicStatusTypes.CURRENT:
      return themeColors.dimBlue;
    case TopicStatusTypes.FINISHED:
      return themeColors.lightGreen;
    case TopicStatusTypes.FUTURE:
      return themeColors.dimGrey;
    default:
      return themeColors.dimGrey;
  }
};

export const countTestStatus = (data: TestProps[]): ChartProps[] => {
  let lockedCount = 0;
  let unlockedCount = 0;
  let completedCount = 0;

  data.forEach((test) => {
    if (test.tests === -1) {
      lockedCount++;
    } else if (test.tests === 0) {
      unlockedCount++;
    } else if (test.tests === 1) {
      completedCount++;
    }
  });

  const chartProps: ChartProps[] = [
    { id: 1, value: completedCount, label: 'Completed', color: themeColors.lightGreen },
    { id: 2, value: unlockedCount, label: 'To do', color: themeColors.dimBlue },
    { id: 3, value: lockedCount, label: 'Locked', color: themeColors.dimLightGrey },
  ];

  return chartProps;
};

export function formatEpochToDate(epoch: number): string {
  const date = new Date(epoch);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
}

export function getDaysSinceUserIsMember(startDateEpoch: number): number {
  const currentDateMillis = new Date().getTime();
  const differenceMillis = currentDateMillis - startDateEpoch;
  const days = Math.floor(differenceMillis / (1000 * 60 * 60 * 24));
  return days - 1;
}

export function generateId(): number {
  return Math.floor(100000 + Math.random() * 900000); // Generates a random number between 100000 and 999999 (6 digits)
}

export function getUserId(state: any): number | null {
  const userDataString = localStorage.getItem(localStorageKeys.userData);
  const savedUserData: UserData = userDataString !== null ? JSON.parse(userDataString) : null;
  const userId =
    savedUserData && savedUserData.user_id !== undefined && savedUserData.user_id !== null
      ? savedUserData.user_id
      : state.userId && state.userId !== undefined && state.userId !== ''
      ? state.userId
      : null;
  return userId;
}

export function getUserEmail(state: any): number | null {
  const userDataString = localStorage.getItem(localStorageKeys.userData);
  const savedUserData: UserData = userDataString !== null ? JSON.parse(userDataString) : null;
  const userEmail =
    savedUserData && savedUserData.email !== undefined && savedUserData.email !== null && savedUserData.email !== ''
      ? savedUserData.email
      : state.userEmail && state.userEmail !== undefined && state.userEmail !== ''
      ? state.userEmail
      : null;
  return userEmail;
}
