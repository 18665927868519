import React, { useEffect, useRef, useState } from 'react';
import { PageHeader } from '../components/common/PageHeader';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Stack, StepConnector } from '@mui/material';
import { localStorageKeys, themeColors } from 'sitevars';
import { GoogleUserData, UserData } from 'interfaces/interfaces';
import { useTheme } from '@mui/material';
import { onboardingSteps } from 'constants/onboardingSteps';
import celebrating from 'assets/celebrating.svg';
import { getDaysSinceUserIsMember } from 'utils/Helpers';

const steps = [
  'First day!',
  'Day 2',
  'Day 3',
  'Halfway through the First Week!',
  'Day 5',
  'Day 6',
  'Last day from First Week!',
];

function Onboarding() {
  const theme = useTheme();
  const [googleUser, setGoogleUser] = useState<GoogleUserData | null>(null);
  const [userDays, setUserDays] = useState<number>(0);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});
  const boxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const user = localStorage.getItem(localStorageKeys.userData);
    if (user) {
      // calculate for how many days the user has been in the platform
      const userData = JSON.parse(user) as UserData;
      const days = getDaysSinceUserIsMember(userData.start_date!);
      if (days >= 7) {
        setUserDays(6);
        setActiveStep(6);
        markStepsAsCompletedForDays();
      } else {
        setUserDays(days - 1);
        setActiveStep(days - 1);
      }
    }

    if (
      localStorage.getItem(localStorageKeys.googleUserData) !== '' ||
      localStorage.getItem(localStorageKeys.googleUserData) !== null ||
      localStorage.getItem(localStorageKeys.googleUserData) !== undefined
    ) {
      const storedData = localStorage.getItem(localStorageKeys.googleUserData);
      if (storedData) {
        const googleUserData = JSON.parse(storedData) as GoogleUserData;
        setGoogleUser(googleUserData);
      }
    }
  }, []);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    setCompleted({
      ...completed,
      [activeStep]: true,
    });
    if (userDays - 1 > activeStep) {
      handleNext();
    }
  };

  const markStepsAsCompletedForDays = () => {
    const newCompleted: { [key: number]: boolean } = {};

    for (let i = 0; i < steps.length; i++) {
      newCompleted[i] = true;
    }

    setCompleted(newCompleted);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  useEffect(() => {
    // scroll to top of email when step or user changes
    if (boxRef.current) {
      boxRef.current.scrollTo(0, 0);
    }
  }, [activeStep, googleUser]);

  return (
    <div>
      <Stack spacing={1} sx={{ backgroundColor: '', paddingBottom: 0 }}>
        <PageHeader title="Onboarding" />
        <Box sx={{ width: '80vw' }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <div>
            {allStepsCompleted() ? (
              <React.Fragment>
                <Typography sx={{ m: 5 }}>
                  Congratulations! All steps completed - you&apos;re finished. Keep up the great work!
                </Typography>
                <img
                  src={celebrating}
                  alt="celebrating"
                  className="rotate-left"
                  style={{
                    width: '30vw',
                    height: '50vh',
                    padding: 30,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                />
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button
                    onClick={handleReset}
                    sx={{
                      textTransform: 'none',
                      backgroundColor: theme.palette.primary.light,
                      margin: 0,
                      padding: 0,
                      borderRadius: 30,
                      paddingX: 1,
                      fontWeight: 0,
                    }}
                  >
                    Reset
                  </Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Box
                  sx={{
                    margin: 5,
                    marginBottom: 1,
                    padding: 3,
                    height: '70vh',
                    borderRadius: 5,
                    backgroundColor: theme.palette.background.paper,
                  }}
                >
                  <Box
                    ref={boxRef}
                    sx={{
                      height: '63vh',
                      overflowY: 'auto',
                      backgroundColor: theme.palette.background.paper,
                    }}
                  >
                    <Typography sx={{ whiteSpace: 'pre-line' }}>
                      {onboardingSteps[activeStep].content(googleUser?.given_name || 'there')}
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{
                      textTransform: 'none',
                      backgroundColor: theme.palette.primary.light,
                      margin: 0,
                      padding: 0,
                      borderRadius: 30,
                      paddingX: 1,
                      fontWeight: 0,
                    }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  {userDays && activeStep < userDays && (
                    <Button
                      onClick={handleNext}
                      sx={{
                        textTransform: 'none',
                        backgroundColor: theme.palette.primary.light,
                        margin: 0,
                        padding: 0,
                        borderRadius: 30,
                        paddingX: 1,
                        fontWeight: 0,
                      }}
                    >
                      Next
                    </Button>
                  )}
                  {activeStep !== steps.length &&
                    (completed[activeStep] ? (
                      <Button
                        variant="contained"
                        disableElevation
                        disabled
                        sx={{
                          backgroundColor: theme.palette.primary.light,
                          textTransform: 'none',
                          margin: 0,
                          padding: 0,
                          borderRadius: 30,
                          paddingX: 1,
                          fontWeight: 0,
                          marginLeft: 1,
                          '&:disabled': {
                            color: themeColors.dimBlue,
                          },
                        }}
                      >
                        Step {activeStep + 1} completed
                      </Button>
                    ) : (
                      <Button
                        onClick={handleComplete}
                        sx={{
                          textTransform: 'none',
                          backgroundColor: theme.palette.primary.light,
                          margin: 0,
                          padding: 0,
                          borderRadius: 30,
                          paddingX: 1,
                          marginLeft: 1,
                          fontWeight: 0,
                        }}
                      >
                        {completedSteps() === totalSteps() - 1 ? 'Finish' : 'Complete Step'}
                      </Button>
                    ))}
                </Box>
              </React.Fragment>
            )}
          </div>
        </Box>
      </Stack>
    </div>
  );
}

export default Onboarding;
