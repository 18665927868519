import React, { useState, useEffect, useContext } from 'react';
import AceEditor from 'react-ace';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from 'context/context';
import { localStorageKeys, apiServerLink, routes, themeColors } from 'sitevars';

// Import the required themes and modes
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-c_cpp';
import 'ace-builds/src-noconflict/mode-csharp';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/theme-github';
import { getUserId } from 'utils/Helpers';
import { Button } from '@mui/material';
import { NotificationTypeEnum } from 'strings';
import ToastAction from './common/ToastAction';
import LoadingDots from './common/Loading';

const CodeEditor: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const initialCode = location.state?.initialCode || '';
  const initialTime = (location.state?.time || 90) * 60; // Default to 90 minutes if not provided
  const difficulty = location.state?.difficulty || '';
  const topic = location.state?.topic || '';
  const { state } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const [code, setCode] = useState<string>(() => {
    const savedCode = localStorage.getItem(localStorageKeys.savedCode);
    return savedCode || initialCode;
  }); // Initial code state
  const [mode, setMode] = useState<string>('javascript');
  const [timeLeft, setTimeLeft] = useState<number>(initialTime);
  const [isTestSessionActive, setIsTestSessionActive] = useState<boolean>(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  //notification toast vars
  const [notification, setNotification] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>('');
  const [notificationType, setNotificationType] = useState<
    typeof NotificationTypeEnum[keyof typeof NotificationTypeEnum]
  >(NotificationTypeEnum.INFO);

  useEffect(() => {
    // Set isTestSessionActive
    if (!localStorage.getItem(localStorageKeys.isTestSessionActive)) {
      localStorage.setItem(localStorageKeys.isTestSessionActive, JSON.stringify(true));
      localStorage.setItem(localStorageKeys.startTime, Date.now().toString());
    }
  }, []);

  useEffect(() => {
    const savedStartTime = localStorage.getItem(localStorageKeys.startTime);
    if (savedStartTime) {
      const elapsedTime = Math.floor((Date.now() - parseInt(savedStartTime, 10)) / 1000); // Convert to seconds
      const newTimeLeft = Math.max(initialTime - elapsedTime, 0);
      setTimeLeft(newTimeLeft);
      localStorage.setItem(localStorageKeys.timeLeft, newTimeLeft.toString());
      if (newTimeLeft === 0) {
        handleEndSession();
      }
    }

    let timer: NodeJS.Timeout;
    if (isTestSessionActive && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          const newTime = prevTime - 1;
          localStorage.setItem(localStorageKeys.timeLeft, newTime.toString());
          return newTime;
        });
      }, 1000);
    } else if (isTestSessionActive && timeLeft === 0) {
      handleEndSession();
    }

    return () => clearInterval(timer);
  }, [isTestSessionActive, timeLeft]);

  const handleChange = (newCode: string) => {
    setCode(newCode);
    localStorage.setItem(localStorageKeys.savedCode, newCode);
  };

  const handleModeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setMode(event.target.value);
  };

  const saveCode = async () => {
    try {
      const userId = getUserId(state);
      await axios.post(`${apiServerLink}/api/save_code`, {
        user_id: userId,
        solutionCode: code,
        difficulty,
        topic,
      });
      // alert('Code saved successfully!');
      // notification for success
      setNotification(true);
      setNotificationText('Test submitted successfully!');
      setNotificationType(NotificationTypeEnum.SUCCESS);

      // Trigger re-fetch here
      fetchChallenges();
      // Redirect to another page
      navigate(routes.tests);
    } catch (error) {
      // console.error('Error saving code:', error);
      // notification for error
      setNotification(true);
      setNotificationText('Test did not submit successfully. Please try again.');
      setNotificationType(NotificationTypeEnum.ERROR);
    }
  };

  const handleEndSession = () => {
    setLoading(true);
    saveCode();
    setIsTestSessionActive(false);
    setIsReadOnly(true);
    localStorage.removeItem(localStorageKeys.timeLeft);
    localStorage.removeItem(localStorageKeys.startTime);
    localStorage.removeItem(localStorageKeys.isTestSessionActive);
    localStorage.removeItem(localStorageKeys.savedCode);
    localStorage.removeItem(localStorage.testLanguage);
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const fetchChallenges = async () => {
    try {
      const userId = getUserId(state);
      if (userId) {
        const response = await axios.get(`${apiServerLink}/api/all_tests?user_id=${userId}`);
        const challenges = response.data;
        localStorage.setItem(localStorageKeys.testsData, JSON.stringify(challenges));
      }
    } catch (error) {
      console.error('Error fetching challenges:', error);
    }
  };

  useEffect(() => {
    // Restore state from localStorage when the component mounts
    const savedTimeLeft = localStorage.getItem(localStorageKeys.timeLeft);
    const savedIsTestSessionActive = localStorage.getItem(localStorageKeys.isTestSessionActive);
    if (savedTimeLeft) {
      setTimeLeft(parseInt(savedTimeLeft, 10));
    }
    if (savedIsTestSessionActive) {
      setIsTestSessionActive(JSON.parse(savedIsTestSessionActive));
    }
  }, []);

  const handleCloseNotification = () => {
    setNotification(false);
    setNotificationText('');
    setNotificationType(NotificationTypeEnum.INFO);
  };

  return (
    <>
      {notification && (
        <ToastAction
          message={notificationText}
          type={notificationType}
          open={notification}
          close={handleCloseNotification}
        />
      )}
      <div style={{ width: '80vw', height: '100vh', overflow: 'hidden' }}>
        <div style={{ padding: '10px', background: '', display: 'flex', justifyContent: 'space-between' }}>
          <div>Time Left: {formatTime(timeLeft)}</div>
          {!loading && (
            <Button
              onClick={handleEndSession}
              sx={{
                textTransform: 'none',
                backgroundColor: themeColors.greenSubmit,
                paddingX: 2,
                paddingY: 0,
                marginLeft: 1,
                fontSize: 12,
                color: 'white',
                '&:hover': {
                  backgroundColor: 'darkgreen',
                },
              }}
            >
              Submit Test
            </Button>
          )}
          {loading && <LoadingDots />}
        </div>
        <AceEditor
          mode={mode}
          readOnly={isReadOnly}
          theme="monokai"
          onChange={handleChange}
          value={code}
          name="code-editor"
          editorProps={{ $blockScrolling: true }}
          showPrintMargin={false}
          width="100%"
          height="calc(100vh - 50px)" // Adjust height to be full-page minus the select box and button height
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
          }}
          onLoad={(editor) => {
            editor.getSession().setUseWorker(false); // Disable syntax validation if needed
          }}
        />
      </div>
    </>
  );
};

export default CodeEditor;
