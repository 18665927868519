import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Grid } from '@mui/material';
import { themeColors } from 'sitevars';
import { TopicNavigationResult } from 'utils/ProblemHelpers';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';

export interface PrevCurrNextCardProps {
  data: TopicNavigationResult;
  title?: string;
}

export function PrevCurrNextCard({ data, title }: PrevCurrNextCardProps): React.JSX.Element {
  return (
    <Box sx={{ minWidth: 275 }}>
      {data.current && title && <Typography variant="h5">Status</Typography>}

      <Timeline position="alternate">
        {data.previous && (
          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: 'auto 0', color: themeColors.deepGreen }}
              align="right"
              variant="body2"
              color="text.secondary"
            >
              Previous
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot sx={{ backgroundColor: themeColors.deepGreen }}>{/* <FastfoodIcon /> */}</TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <Typography variant="h6" component="span">
                {data.previous.topic}
              </Typography>
              <Typography> {data.previous.difficulty}</Typography>
            </TimelineContent>
          </TimelineItem>
        )}

        {data.current && (
          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: 'auto 0', color: themeColors.muiDarkBlue }}
              variant="body2"
              color="text.secondary"
            >
              Current
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot sx={{ backgroundColor: themeColors.muiDarkBlue }}>{/* <LaptopMacIcon /> */}</TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <Typography variant="h6" component="span">
                {data.current.topic}
              </Typography>
              <Typography> {data.current.difficulty}</Typography>
            </TimelineContent>
          </TimelineItem>
        )}

        {data.next && (
          <TimelineItem>
            <TimelineOppositeContent sx={{ m: 'auto 0' }} align="right" variant="body2" color="text.secondary">
              Next
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot>{/* <FastfoodIcon /> */}</TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <Typography variant="h6" component="span">
                {data.next.topic}
              </Typography>
              <Typography> {data.next.difficulty}</Typography>
            </TimelineContent>
          </TimelineItem>
        )}
        {/* 
        <TimelineItem>
          <TimelineSeparator>
            <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
            <TimelineDot color="secondary"><RepeatIcon /> </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant="h6" component="span">
              Repeat
            </Typography>
            <Typography>Because this is the life you love!</Typography>
          </TimelineContent>
        </TimelineItem> */}
      </Timeline>
    </Box>
  );
}
