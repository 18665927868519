import React from 'react';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { useTheme as useGlobalTheme } from 'context/GlobalThemeProvider';
// import { darkTheme, lightTheme } from 'theme';

const DarkLightModeButton: React.FC = () => {
  const { toggleTheme, themeMode } = useGlobalTheme();
  const theme = useTheme();

  const isDarkMode = themeMode === 'dark';

  const lightIconColor = isDarkMode ? theme.palette.text.secondary : theme.palette.primary.main;
  const darkIconColor = isDarkMode ? theme.palette.primary.main : theme.palette.text.secondary;

  // const lightIconColorBackground = isDarkMode ? darkTheme.palette.background.paper : '#cfd8dc';
  // const darkIconColorBackground = isDarkMode ? '#212121' : lightTheme.palette.background.paper;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 20,
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          height: 25,
          width: 80,
          backgroundColor: theme.palette.primary.light,
          borderRadius: 12,
          padding: 2,
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          style={{
            borderRadius: 12,
            width: 25,
            height: 25,
          }}
        >
          <IconButton
            disableRipple
            onClick={() => toggleTheme()}
            sx={{
              color: lightIconColor,
              padding: 0,
              margin: 0,
            }}
          >
            <LightModeIcon sx={{ fontSize: 20 }} />
          </IconButton>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          style={{
            borderRadius: 12,
            width: 25,
            height: 25,
          }}
        >
          <IconButton
            disableRipple
            onClick={() => toggleTheme()}
            sx={{
              color: darkIconColor,
              padding: 0,
              margin: 0,
            }}
          >
            <DarkModeIcon sx={{ fontSize: 20 }} />
          </IconButton>
        </Box>
      </Box>

      <Typography sx={{ paddingLeft: 1, fontWeight: 300 }}>{isDarkMode ? 'Dark Mode' : 'Light Mode'}</Typography>
    </div>
  );
};

export default DarkLightModeButton;
