import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import FileCopySharpIcon from '@mui/icons-material/FileCopySharp';
import SourceSharpIcon from '@mui/icons-material/SourceSharp';
import StarSharpIcon from '@mui/icons-material/StarSharp';
import ExploreSharpIcon from '@mui/icons-material/ExploreSharp';
import Person2SharpIcon from '@mui/icons-material/Person2Sharp';
import TimelineSharpIcon from '@mui/icons-material/TimelineSharp';
import SpeedSharpIcon from '@mui/icons-material/SpeedSharp';
import WorkSharpIcon from '@mui/icons-material/WorkSharp';
import FlagSharpIcon from '@mui/icons-material/FlagSharp';
import TrackChangesSharpIcon from '@mui/icons-material/TrackChangesSharp';
import { AppContext } from 'context/context';
import { useContext, useEffect, useState } from 'react';
import { PortalSectionTypeEnum, strings } from 'strings';
import { useNavigate } from 'react-router-dom';
import { Types } from 'context/reducers';
import { localStorageKeys, routes } from 'sitevars';
import { UserData } from 'interfaces/interfaces';
import DriveFileRenameOutlineSharpIcon from '@mui/icons-material/DriveFileRenameOutlineSharp';
import CodeSharpIcon from '@mui/icons-material/CodeSharp';
import { useTheme } from '@mui/material';
import { getDaysSinceUserIsMember } from 'utils/Helpers';

export default function PortalSection() {
  const theme = useTheme();
  const { state, dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const [user, setUser] = useState<UserData | null>(null);

  useEffect(() => {
    const storedData = localStorage.getItem(localStorageKeys.userData);
    if (storedData) {
      const userData = JSON.parse(storedData);
      setUser(userData);
    }
  }, []);

  var homeItems = [
    {
      text: 'Home',
      icon: <ExploreSharpIcon sx={{ fontSize: 20, color: theme.palette.action.active }} />,
      section: PortalSectionTypeEnum.HOME,
      path: routes.home,
    },
    {
      text: 'Tracker',
      icon: <TrackChangesSharpIcon sx={{ fontSize: 20, color: theme.palette.action.active }} />,
      section: PortalSectionTypeEnum.TRACKER,
      path: routes.tracker,
    },
    {
      text: 'Targets',
      icon: <TimelineSharpIcon sx={{ fontSize: 20, color: theme.palette.action.active }} />,
      section: PortalSectionTypeEnum.TARGETS,
      path: routes.targets,
    },
    {
      text: 'Tests',
      icon: <FileCopySharpIcon sx={{ fontSize: 20, color: theme.palette.action.active }} />,
      section: PortalSectionTypeEnum.TESTS,
      path: routes.tests,
    },
    {
      text: 'Problems',
      icon: <CodeSharpIcon sx={{ fontSize: 20, color: theme.palette.action.active }} />,
      section: PortalSectionTypeEnum.PROBLEMS,
      path: routes.problems,
    },
    {
      text: 'Onboarding',
      icon: <FlagSharpIcon sx={{ fontSize: 20, color: theme.palette.action.active }} />,
      section: PortalSectionTypeEnum.ONBOARDING,
      path: routes.oboarding,
    },
    {
      text: 'Jobs Tracker',
      icon: <WorkSharpIcon sx={{ fontSize: 20, color: theme.palette.action.active }} />,
      section: PortalSectionTypeEnum.JOB_APPLICATIONS,
      path: routes.jobsTracker,
    },
  ];

  const savedUserData = localStorage.getItem(localStorageKeys.googleUserData);
  if (state && user?.role === 'admin') {
    const adminMenus = [
      {
        text: '-- Admin --',
        icon: <Person2SharpIcon sx={{ fontSize: 20, color: theme.palette.background.default }} />,
        section: PortalSectionTypeEnum.ADMIN,
        path: routes.admin,
      },
      {
        text: 'LC Tracker',
        icon: <FileCopySharpIcon sx={{ fontSize: 20, color: theme.palette.action.active }} />,
        section: PortalSectionTypeEnum.ADMIN,
        path: routes.admin,
      },
      {
        text: 'Grade Tests',
        icon: <DriveFileRenameOutlineSharpIcon sx={{ fontSize: 20, color: theme.palette.action.active }} />,
        section: PortalSectionTypeEnum.ADMIN_TESTS_GRADE,
        path: routes.adminTestGrading,
      },
    ];

    homeItems.push(...adminMenus);
  }

  return (
    <div
      style={{
        backgroundColor: theme.palette.background.default,
        paddingTop: 15,
        paddingLeft: 3,
        borderTopLeftRadius: '15px',
        borderBottomLeftRadius: '20px',
        height: '100vh',
      }}
    >
      <Typography
        style={{
          paddingTop: 0,
          paddingLeft: 5,
          display: 'flex',
          alignItems: 'center',
          fontSize: 20,
          fontWeight: 'bold',
        }}
      >
        {strings.mainSectionSidebarTitle}
      </Typography>

      <List>
        {homeItems?.map((item) => (
          <ListItem
            key={item.text}
            style={{
              padding: 0,
              marginLeft: 4,
              backgroundColor: '',
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate(item.path);
              dispatch({
                type: Types.PortalNavType,
                payload: { portalNav: item.section },
              });
            }}
          >
            <ListItemIcon
              style={{
                padding: 0,
                margin: 5,
                backgroundColor: '',
                minWidth: 25,
              }}
            >
              {item.icon}
            </ListItemIcon>
            <Typography style={{ fontWeight: '300', paddingTop: 2 }}>{item.text}</Typography>
          </ListItem>
        ))}
      </List>
    </div>
  );
}
