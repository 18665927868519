import { Button, Tooltip } from '@mui/material';
import React from 'react';
import { themeColors, webRoutes } from 'sitevars';

type Props = {
  text?: string;
  tooltipText?: string;
};

export default function PremiumButton({ text = 'Premium', tooltipText = '' }: Props) {
  return (
    <Tooltip title={tooltipText}>
      <Button
        disabled={true}
        disableElevation
        style={{
          textTransform: 'none',
          fontWeight: '600',
          paddingTop: '2px',
          paddingBottom: '2px',
          paddingRight: '10px',
          paddingLeft: '10px',
          backgroundColor: themeColors.muiPrimaryBlue,
          color: 'white',
          borderRadius: '20px',
        }}
        // onClick={() => window.open(`${webAppLink}${webRoutes.pricing}`, '_blank')}
      >
        {text}
      </Button>
    </Tooltip>
  );
}
