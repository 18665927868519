import React from 'react';
import { Card, CardContent, Typography, CardMedia, Box } from '@mui/material';
import { ProcessedEvent } from 'interfaces/interfaces';
import Carousel from 'react-material-ui-carousel';
import dayjs from 'dayjs';
import PhoneSharpIcon from '@mui/icons-material/PhoneSharp';
import { themeColors } from 'sitevars';

interface EventCarouselProps {
  events: ProcessedEvent[];
}

export default function EventsCarousel({ events }: EventCarouselProps) {
  const formatEventDate = (start: Date, end: Date) => {
    const startDate = dayjs(start);
    const endDate = dayjs(end);

    const startDateFormatted = startDate.format('D MMM YYYY');
    const startTimeFormatted = startDate.format('h:mma');
    const endTimeFormatted = endDate.format('h:mma');

    return `${startDateFormatted} | ${startTimeFormatted} - ${endTimeFormatted}`;
  };

  return (
    <Carousel
      navButtonsAlwaysVisible={true}
      indicators={true}
      sx={{
        backgroundColor: '',
        width: '30vw',
        height: '40vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
      }}
    >
      {events.map((event) => (
        <Box
          key={event.id}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            flexDirection: 'column',
            textAlign: 'center',
            padding: 0,
            margin: 0,
          }}
        >
          <Card key={event.id} sx={{ width: '22vw', height: '22vh', marginTop: 1 }}>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '16px',
                height: '100%',
              }}
            >
              <Typography gutterBottom sx={{ fontSize: 16, fontWeight: 600 }}>
                {event.title}
              </Typography>
              <Typography variant="body2" sx={{ color: themeColors.muiPrimaryBlue }}>
                {formatEventDate(event.start, event.end)}
              </Typography>
              <Typography
                color="text.secondary"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 1,
                  fontSize: 12,
                  paddingTop: 1,
                }}
              >
                <PhoneSharpIcon sx={{ fontSize: 14 }} />
                <a
                  href={event.meetLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: 'inherit',
                    textDecoration: 'none',
                  }}
                >
                  Click to Join with Google Meet
                </a>
              </Typography>
            </CardContent>
          </Card>
        </Box>
      ))}
    </Carousel>
  );
}
