import React, { useEffect } from 'react';
import { AppContext } from 'context/context';
import PortalSection from './sections/PortalSection';
import NotificationSection from './sections/NotificationSection';
import { SectionTypeEnum } from 'strings';

const PortalSidebar = () => {
  const { state, dispatch } = React.useContext(AppContext);

  return (
    <div
      style={{
        backgroundColor: '#0d87e9',
        width: 150,
        borderRight: '1px solid lightgrey',
      }}
    >
      {state.sectionNav === SectionTypeEnum.PORTAL && <PortalSection />}
      {state.sectionNav === SectionTypeEnum.NOTIFICATIONS && <NotificationSection />}
    </div>
  );
};

export default PortalSidebar;
