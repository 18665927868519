import React, { useEffect, useContext } from 'react';
import { Container, Icon, Typography } from '@mui/material';
import PageNotFoundSvg from 'assets/pageNotFound.svg';

export default function PageNotFound() {
  return (
    <Container style={{ backgroundColor: '', paddingTop: 20, justify: 'center' }}>
      <Typography sx={{ fontSize: 22, fontWeight: 'bold', pt: 5, pl: 3 }}>Sorry, there was a problem!</Typography>
      <Typography sx={{ fontWeight: 300, fontSize: 16, pt: 5, pl: 3 }}>
        Something went wrong - please contact the Administrator.
      </Typography>
      <Icon component="img" src={PageNotFoundSvg} alt="404" style={{ fontSize: 400, paddingLeft: 50 }} />
    </Container>
  );
}
