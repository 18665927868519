type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum Types {
  SectionNavType = 'SET_NAV_SECTION',
  PortalNavType = 'SET_PORTAL_NAV_SECTION',
  MembershipType = 'MEMBERSHIP',
  UserEmailType = 'USER_EMAIL',
  UserIdType = 'USER_ID',
}

// --------------- MEMBERSHIP ---------------
export type MembershipType = {
  membership: string;
};

type MembershipPayload = {
  [Types.MembershipType]: {
    membership: string;
  };
};

export type MembershipAction = ActionMap<MembershipPayload>[keyof ActionMap<MembershipPayload>];

// --------------- USER ID ---------------
export type UserIdType = {
  userId: number | null;
};

type UserIdPayload = {
  [Types.UserIdType]: {
    userId: number | null;
  };
};

export type UserIdAction = ActionMap<UserIdPayload>[keyof ActionMap<UserIdPayload>];

// --------------- USER EMAIL ---------------
export type UserEmailType = {
  userEmail: string | undefined | null;
};

type UserEmailPayload = {
  [Types.UserEmailType]: {
    userEmail: string | undefined | null;
  };
};

export type UserEmailAction = ActionMap<UserEmailPayload>[keyof ActionMap<UserEmailPayload>];

// --------------- SECTION NAV ---------------
export type SectionNavType = {
  sectionNav: string;
};

type SectionNavPayload = {
  [Types.SectionNavType]: {
    sectionNav: string;
  };
};

export type SectionNavActions = ActionMap<SectionNavPayload>[keyof ActionMap<SectionNavPayload>];

// --------------- PORTAL SECTION NAV ---------------
export type PortalNavType = {
  portalNav: string;
};

type PortalNavPayload = {
  [Types.PortalNavType]: {
    portalNav: string;
  };
};

export type PortalNavActions = ActionMap<PortalNavPayload>[keyof ActionMap<PortalNavPayload>];

// -----------------------------------------------

export const combinedReducers = (state: any, action: any) => {
  switch (action.type) {
    case Types.SectionNavType:
      return {
        ...state,
        sectionNav: action.payload.sectionNav,
      };

    case Types.PortalNavType:
      return {
        ...state,
        portalNav: action.payload.portalNav,
      };

    case Types.MembershipType:
      return {
        ...state,
        membership: action.payload.membership,
      };

    case Types.UserEmailType:
      return {
        ...state,
        userEmail: action.payload.userEmail,
      };

    case Types.UserIdType:
      return {
        ...state,
        userId: action.payload.userId,
      };

    default:
      return state;
  }
};
