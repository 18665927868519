import {
  LeetcodeLevels,
  Problem,
  ProblemSolvedStatus,
  TestProps,
  TopicStatus,
  TopicStatusTypes,
} from 'interfaces/interfaces';

// transform raw problem data from db into Problem[] format
export const transformRawData = (data: any): Problem[] => {
  const transformedData: Problem[] = [];
  for (const key in data.titleSlug) {
    if (data.titleSlug.hasOwnProperty(key)) {
      transformedData.push({
        titleSlug: data.titleSlug[key],
        acRate: data.acRate[key],
        difficulty: data.difficulty[key].toLowerCase(),
        title: data.title[key],
        topicTags: data.topicTags[key],
        topic_final_status: data.topic_final_status[key],
        solve_status: data.solve_status[key],
        topic_target: data.topic_target[key],
        count: data.count[key],
      });
    }
  }
  return transformedData;
};

export const createTopicStatusMap = (data: Problem[]): TopicStatus[] => {
  const topicStatusMap: Record<string, Record<string, TopicStatusTypes>> = {};

  data.forEach((problem) => {
    if (!topicStatusMap[problem.difficulty]) {
      topicStatusMap[problem.difficulty] = {};
    }
    topicStatusMap[problem.difficulty][problem.topicTags] = problem.topic_final_status as TopicStatusTypes;
  });

  const result: TopicStatus[] = [];
  for (const difficulty in topicStatusMap) {
    for (const topicTag in topicStatusMap[difficulty]) {
      result.push({
        topic: topicTag,
        status: topicStatusMap[difficulty][topicTag],
        difficulty: difficulty as LeetcodeLevels,
      });
    }
  }

  result.sort((a, b) => {
    const difficultyComparison = difficultyOrder.indexOf(a.difficulty) - difficultyOrder.indexOf(b.difficulty);
    if (difficultyComparison !== 0) {
      return difficultyComparison;
    }

    const orderA = tagOrder[a.difficulty]?.[a.topic] ?? Number.MAX_SAFE_INTEGER;
    const orderB = tagOrder[b.difficulty]?.[b.topic] ?? Number.MAX_SAFE_INTEGER;
    return orderA - orderB;
  });

  return result;
};

export const countTopicStatus = (topics: TopicStatus[]) => {
  let totalTargets = topics.length;
  let completedCount = 0;
  let inProgressCount = 0;
  let lockedCount = 0;

  topics.forEach((topic) => {
    switch (topic.status) {
      case TopicStatusTypes.FINISHED:
        completedCount++;
        break;
      case TopicStatusTypes.CURRENT:
        inProgressCount++;
        break;
      case TopicStatusTypes.FUTURE:
        lockedCount++;
        break;
      default:
        break;
    }
  });

  return {
    totalTargets,
    completedCount,
    inProgressCount,
    lockedCount,
  };
};

export const filterDataByTopicAndDifficulty = (
  sortedData: Problem[],
  selectedDifficulty: string,
  selectedTag: string
): Problem[] => {
  // Filter the data based on selected difficulty and topic tag
  return sortedData.filter((problem) => problem.difficulty === selectedDifficulty && problem.topicTags === selectedTag);
};

export const countFailedProblems = (problems: Problem[]): number => {
  return problems.filter((problem) => problem.solve_status === ProblemSolvedStatus.FAILED).length;
};

const solveStatusOrder: Record<string, number> = {
  solved: 0,
  failed_today: 1,
  failed: 2,
};

export const sortDataBySolveStatusAndAcRate = (data: Problem[]): Problem[] => {
  return [...data].sort((a, b) => {
    const statusOrderA = solveStatusOrder[a.solve_status];
    const statusOrderB = solveStatusOrder[b.solve_status];

    if (statusOrderA !== statusOrderB) {
      return statusOrderA - statusOrderB;
    } else {
      return b.acRate - a.acRate; // Sort acRate in descending order
    }
  });
};

export const difficultyOrder: LeetcodeLevels[] = [LeetcodeLevels.EASY, LeetcodeLevels.MEDIUM, LeetcodeLevels.HARD];

type TagOrderType = {
  [key in LeetcodeLevels]: { [topic: string]: number };
};

export const tagOrder: TagOrderType = {
  [LeetcodeLevels.EASY]: {
    'array': 1,
    'string': 2,
    'hash-table': 3,
    'binary-search': 4,
    'recursion': 5,
    'tree': 6,
    'backtracking': 7,
    'dynamic-programming': 8,
    'breadth-first-search': 9,
    'depth-first-search': 10,
  },
  [LeetcodeLevels.MEDIUM]: {
    'array': 1,
    'string': 2,
    'breadth-first-search': 3,
    'depth-first-search': 4,
  },
  [LeetcodeLevels.HARD]: {},
};

export type TopicNavigationResult = {
  current: { difficulty: LeetcodeLevels; topic: string } | null;
  previous: { difficulty: LeetcodeLevels; topic: string } | null;
  next: { difficulty: LeetcodeLevels; topic: string } | null;
};

export const getPreviousAndNextTopic = (
  currentDifficulty: LeetcodeLevels,
  currentTopic: string
): TopicNavigationResult => {
  const currentDifficultyIndex = difficultyOrder.indexOf(currentDifficulty);
  const topicsInCurrentDifficulty = Object.keys(tagOrder[currentDifficulty]);
  const currentTopicIndex = topicsInCurrentDifficulty.indexOf(currentTopic);

  let current: { difficulty: LeetcodeLevels; topic: string } = {
    difficulty: currentDifficulty,
    topic: currentTopic,
  };
  let previous: { difficulty: LeetcodeLevels; topic: string } | null = null;
  let next: { difficulty: LeetcodeLevels; topic: string } | null = null;

  // Find previous topic
  if (currentTopicIndex > 0) {
    previous = {
      difficulty: currentDifficulty,
      topic: topicsInCurrentDifficulty[currentTopicIndex - 1],
    };
  } else if (currentDifficultyIndex > 0) {
    const previousDifficulty = difficultyOrder[currentDifficultyIndex - 1];
    const topicsInPreviousDifficulty = Object.keys(tagOrder[previousDifficulty]);
    previous = {
      difficulty: previousDifficulty,
      topic: topicsInPreviousDifficulty[topicsInPreviousDifficulty.length - 1],
    };
  }

  // Find next topic
  if (currentTopicIndex < topicsInCurrentDifficulty.length - 1) {
    next = {
      difficulty: currentDifficulty,
      topic: topicsInCurrentDifficulty[currentTopicIndex + 1],
    };
  } else if (currentDifficultyIndex < difficultyOrder.length - 1) {
    const nextDifficulty = difficultyOrder[currentDifficultyIndex + 1];
    const topicsInNextDifficulty = Object.keys(tagOrder[nextDifficulty]);
    next = {
      difficulty: nextDifficulty,
      topic: topicsInNextDifficulty[0],
    };
  }

  return { current, previous, next };
};

export const getCurrentTopic = (topicsStatusData: TopicStatus[]): TopicStatus | null => {
  for (const topicStatus of topicsStatusData) {
    if (topicStatus.status === TopicStatusTypes.CURRENT) {
      return topicStatus;
    }
  }
  return null;
};

export const getPreviousAndNextTest = (tests: TestProps[]): TopicNavigationResult => {
  // Sort the array by id to ensure correct ordering
  const sortedTests = [...tests].sort((a, b) => parseInt(a.id) - parseInt(b.id));

  let current: { difficulty: LeetcodeLevels; topic: string } | null = null;
  let previous: { difficulty: LeetcodeLevels; topic: string } | null = null;
  let next: { difficulty: LeetcodeLevels; topic: string } | null = null;

  for (let i = 0; i < sortedTests.length; i++) {
    const test = sortedTests[i];
    if (test.tests === 0) {
      current = { difficulty: test.difficulty as LeetcodeLevels, topic: test.topicTags };
      previous =
        i > 0
          ? { difficulty: sortedTests[i - 1].difficulty as LeetcodeLevels, topic: sortedTests[i - 1].topicTags }
          : null;
      next =
        i < sortedTests.length - 1
          ? { difficulty: sortedTests[i + 1].difficulty as LeetcodeLevels, topic: sortedTests[i + 1].topicTags }
          : null;
      break; // Found the current topic, break the loop
    }
  }

  return { current, previous, next };
};
