import { Box, Button, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from 'assets/topcoding-no-white.png';
import { AppContext } from 'context/context';
import { Types } from 'context/reducers';
import { useGoogleLogin } from '@react-oauth/google';
import { localStorageKeys, routes, apiServerLink, useTestUser, testUserEmail, themeColors } from 'sitevars';
import axios from 'axios';
import Spinner from 'components/common/Spinner';
import { styled } from '@mui/material/styles';

const FlickerButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  color: 'white',
  backgroundColor: '#3983F6',
  padding: '10px 20px',
  borderRadius: 2,
  transition: 'background-color 0.3s ease, transform 0.3s ease',
  overflow: 'hidden',
  '&:hover': {
    backgroundColor: '#3982F7',
    transform: 'scale(1.2)',
  },
  '&:hover::before': {
    transform: 'translateX(100%)',
  },
  '&:active': {
    transform: 'scale(0.98)',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: '-100%',
    width: '100%',
    height: '100%',
    background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.2), transparent)',
    transition: 'transform 0.5s ease',
    zIndex: 1,
    transform: 'translateX(-100%)',
  },
}));

const GetStarted = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);
  const [loading, setLoading] = useState(true);

  const handleNavigate = () => {
    navigate(routes.home);
  };

  const handleLoginSuccess = async (tokenResponse: any) => {
    setLoading(true);
    try {
      const userInfoResponse = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
        headers: {
          Authorization: `Bearer ${tokenResponse.access_token}`,
        },
      });

      const userInfo = userInfoResponse.data;

      const dbUser = await axios.get(`${apiServerLink}/api/user_data/${userInfo.email}`);

      if (dbUser && dbUser.data.length > 0) {
        setUserEmail(userInfo.email);
        setUserId(dbUser.data[0].user_id);
        localStorage.setItem(localStorageKeys.googleUserData, JSON.stringify(userInfo));
        const dbUserObject = dbUser.data[0];
        localStorage.setItem(localStorageKeys.userData, JSON.stringify(dbUserObject));
        localStorage.setItem(localStorageKeys.isUserAuthorized, JSON.stringify(true));
        setLoading(false);
        handleNavigate();
      } else {
        localStorage.setItem(localStorageKeys.isUserAuthorized, JSON.stringify(false));
        setLoading(false);
        navigate(routes.pageNotFound);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching user info while authentication:', error);
    }
  };

  const handleLogin = useGoogleLogin({
    onSuccess: (response) => {
      handleLoginSuccess(response);
    },
    onError: () => console.log('Login Failed'),
  });

  const setUserId = (user_id: number | null | undefined) => {
    if (user_id) {
      dispatch({
        type: Types.UserIdType,
        payload: {
          userId: user_id,
        },
      });
    }
  };

  useEffect(() => {
    const storedData = localStorage.getItem(localStorageKeys.userData);
    if (storedData) {
      const userData = JSON.parse(storedData);
      if (
        userData.email &&
        userData.email !== '' &&
        userData.email !== undefined &&
        userData.user_id &&
        userData.user_id !== '' &&
        userData.user_id !== undefined
      ) {
        handleNavigate();
      }
    }
    setLoading(false);
  }, []);

  const setUserEmail = (email: string | null | undefined) => {
    if (email) {
      dispatch({
        type: Types.UserEmailType,
        payload: {
          userEmail: useTestUser ? testUserEmail : email,
        },
      });
    }
  };

  return (
    <div
      style={{
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'center',
        paddingTop: 200,
        backgroundColor: '',
      }}
    >
      {!loading && (
        <>
          <img src={logo} alt="Logo" className="rotate-left" style={{ width: '20vw', paddingBottom: 50 }} />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingBottom: 5,
            }}
          >
            <Typography
              sx={{
                fontFamily: 'monospace', // Optional: gives it a more "typing" feel
                whiteSpace: 'pre', // Ensures that spaces are preserved
              }}
            >
              Your journey starts here
            </Typography>
          </Box>
          <FlickerButton onClick={() => handleLogin()}>Get started</FlickerButton>
        </>
      )}
      {loading && <Spinner />}
    </div>
  );
};

export default GetStarted;
