import { Box, Divider, Typography } from '@mui/material';
import React from 'react';

interface PageHeaderProps {
  title: string;
  icon?: React.ReactNode;
}

export const PageHeader: React.FC<PageHeaderProps> = ({ title, icon }) => {
  return (
    <Box sx={{ padding: 0, paddingBottom: 1, backgroundColor: '' }}>
      <Typography variant="h4">{title}</Typography>
      {/* <Divider sx={{ flexGrow: 1 }} /> */}
    </Box>
  );
};
