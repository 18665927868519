import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import Badge from '@mui/material/Badge';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import { ProcessedEvent } from 'interfaces/interfaces';
import { themeColors } from 'sitevars';
import { Box, SxProps, Theme } from '@mui/material';

function getDaysWithEvents(events: ProcessedEvent[], month: Dayjs) {
  return events
    .filter((event) => dayjs(event.start).month() === month.month())
    .map((event) => dayjs(event.start).date());
}

interface ServerDayProps extends PickersDayProps<Dayjs> {
  highlightedDays?: number[];
  highlightColor?: string;
}

const initialValue = dayjs();

function ServerDay(props: ServerDayProps) {
  const {
    highlightedDays = [],
    highlightColor = themeColors.muiPrimaryBlue,
    day,
    outsideCurrentMonth,
    ...other
  } = props;

  const isHighlighted = !outsideCurrentMonth && highlightedDays.includes(day.date());

  return (
    <Badge
      key={day.toString()}
      overlap="circular"
      badgeContent={undefined}
      sx={{
        '& .MuiBadge-badge': {
          backgroundColor: isHighlighted ? highlightColor : 'transparent',
          borderRadius: '50%',
          minWidth: 36,
          height: 36,
        },
      }}
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        sx={{
          bgcolor: isHighlighted ? highlightColor : undefined,
          color: isHighlighted ? 'white' : undefined,
          borderRadius: '50%',
          width: 36,
          height: 36,
          '&.Mui-selected': {
            bgcolor: isHighlighted ? highlightColor : undefined,
          },
        }}
      />
    </Badge>
  );
}

const defaultSx: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  backgroundColor: '',
  width: '30vw',
};

export default function DateCalendarServerRequest({
  events,
  sx = {},
}: {
  events?: ProcessedEvent[];
  sx?: SxProps<Theme>;
}) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [highlightedDays, setHighlightedDays] = React.useState<number[]>([]);

  const fetchHighlightedDays = (date: Dayjs) => {
    if (events && events.length > 0) {
      setIsLoading(true);
      const daysWithEvents = getDaysWithEvents(events, date);
      setHighlightedDays(daysWithEvents);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (events && events.length > 0) {
      fetchHighlightedDays(initialValue);
    }
  }, [events]);

  const handleMonthChange = (date: Dayjs) => {
    setIsLoading(true);
    setHighlightedDays([]);
    fetchHighlightedDays(date);
  };

  return (
    <Box sx={sx}>
      <DateCalendar
        readOnly
        defaultValue={initialValue}
        loading={isLoading}
        onMonthChange={handleMonthChange}
        renderLoading={() => <DayCalendarSkeleton />}
        views={['day']}
        slots={{
          day: ServerDay,
        }}
        slotProps={{
          day: {
            highlightedDays,
          } as any,
        }}
      />
    </Box>
  );
}
