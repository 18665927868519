import React, { useState, useEffect, useMemo } from 'react';
import '../../style/Spinner.css'; // Ensure this path is correct
import { themeColors } from 'sitevars';

const Spinner = () => {
  const loadingMessages = useMemo(() => ['Loading...', 'Please wait...', 'Fetching data...', 'Almost there...'], []);
  const [currentMessage, setCurrentMessage] = useState<string>(loadingMessages[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessage((prevMessage) => {
        const currentIndex = loadingMessages.indexOf(prevMessage);
        const nextIndex = (currentIndex + 1) % loadingMessages.length;
        return loadingMessages[nextIndex];
      });
    }, 2000); // Change text every 2 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [loadingMessages]);

  return (
    <div className="spinner-container">
      <div className="spinner">
        <div className="double-bounce1"></div>
        <div className="double-bounce2"></div>
      </div>
      <div className="loading-text" style={{ color: themeColors.muiPrimaryBlue }}>
        {currentMessage}
      </div>
    </div>
  );
};

export default Spinner;
