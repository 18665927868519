import React, { createContext, useState, useContext, ReactNode } from 'react';

export enum ThemeModeType {
  LIGHT = 'light',
  DARK = 'dark',
}

interface ThemeContextType {
  themeMode: ThemeModeType;
  toggleTheme: () => void;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

export const GlobalThemeProvider = ({ children }: { children: ReactNode }) => {
  const [themeMode, setThemeMode] = useState<ThemeModeType>(ThemeModeType.DARK);

  const toggleTheme = () => {
    setThemeMode((prevMode) => (prevMode === ThemeModeType.DARK ? ThemeModeType.LIGHT : ThemeModeType.DARK));
  };

  return <ThemeContext.Provider value={{ themeMode, toggleTheme }}>{children}</ThemeContext.Provider>;
};
