import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import { Typography } from '@mui/material';
import ExitToAppSharpIcon from '@mui/icons-material/ExitToAppSharp';
import LogoutSharpIcon from '@mui/icons-material/LogoutSharp';

interface LogoutButtonProps {
  handleLogout: () => void;
}

export const LogoutButton: React.FC<LogoutButtonProps> = ({ handleLogout }) => {
  return (
    <ListItemButton id="logout-button" onClick={handleLogout}>
      <LogoutSharpIcon sx={{ fontSize: 18, color: '#404040', paddingRight: 0 }} />
      <Typography sx={{ fontWeight: 300, paddingLeft: '2px' }}>Logout</Typography>
    </ListItemButton>
  );
};
