export interface TopicMetadata {
  [id: string]: {
    title: string;
    description: string;
  };
}

export interface Problem {
  titleSlug: string;
  acRate: number;
  difficulty: string;
  title: string;
  topicTags: string;
  topic_final_status: string;
  solve_status: string;
  topic_target: number;
  count: number;
}

export interface TopicTag {
  id: string;
  name: string;
  slug: string;
}

export enum TopicStatusTypes {
  CURRENT = 'current',
  FINISHED = 'finished',
  FUTURE = 'future',
}

export interface TopicStatus {
  topic: string;
  difficulty: LeetcodeLevels;
  status: TopicStatusTypes;
}

export interface TestProps {
  id: string;
  title: string;
  time: number;
  tests: number;
  difficulty: string;
  topicTags: string;
  results: number;
  feedback: string;
  // initialCode?: string; //TODO use the initial code directly from here because we have it in the data
}

export enum ProblemSolvedStatus {
  SOLVED = 'solved',
  FAILED = 'failed',
  UNATTEMPTED = 'unattempted',
}

export enum LeetcodeLevels {
  EASY = 'easy',
  MEDIUM = 'medium',
  HARD = 'hard',
}

export interface UserData {
  user_id?: number;
  email: string;
  end_date: number | null;
  family_name: string;
  given_name: string;
  lc_user: string;
  name: string;
  role: string;
  picture?: string;
  start_date: number | null; // Assuming this is a timestamp (Unix epoch in milliseconds)
}

export interface GoogleUserData {
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  name: string;
  picture: string;
  sub: string;
}

export interface TestData {
  email: string;
  name: string;
  lc_user: string;
  lc_user_x: string;
  lc_user_y: string;
  difficulty: string;
  topicTags: string;
  tests: number;
  sprints: number;
  results: number | null;
  feedback: string | null;
  tests_data: string;
  user_id: number;
}

export interface InHouseProblem {
  question_id: number;
  difficulty: string;
  title: string;
  topic_tags: string[];
}

export interface ProcessedEvent {
  id: string;
  title: string;
  start: Date;
  end: Date;
  description?: string;
  meetLink?: string;
}

export interface GoogleCalendarEvent {
  id: string;
  summary: string;
  description: string;
  start: {
    dateTime: string;
  };
  end: {
    dateTime: string;
  };
  hangoutLink?: string;
}

export const languageOptions = ['cpp', 'csharp', 'java', 'javascript', 'python3', 'typescript'];

export const programmingLanguages = {
  python: 'Python3',
  javascript: 'JavaScript',
  // cpp: 'C++',
  // csharp: 'C#',
  // java: 'Java',
};

export interface Submission {
  question_id: number;
  submission_time: number;
  code: string;
  stdin: string;
  expected_output: string;
  final_status: string;
  language: {
    id: number;
    name: string;
  };
  memory: number;
  status: {
    id: number;
    description: string;
  };
  stderr: string | null;
  stdout: string;
  time: string;
}
export interface IdeProblem {
  id: string;
  title: string;
  code: string;
  initialCode: string;
  // testCases: string;
  submissions: Submission[];
  questionContent: string;
  solutions: string;
}

export type LcUserIdData = {
  lc_user: string;
  user_id: number;
};

export const jobStageOptions = [
  'HR Screening',
  'Tech Screening',
  'Home Task',
  '1st Round',
  '2nd Round',
  'Onsite',
  'Final',
  'All completed',
];

// export const jobStatusOptions = ['📝 ToDo', '✔️ Applied', '🎬 Interviewing', '❌ Rejected', '☠️ Ghosted', '✅ Offer'];
export const jobStatusOptions = ['ToDo', 'Applied', 'Interviewing', 'On pause', 'Dropped', 'Finished'];
export const jobApplicationOutcome = [
  'Pending',
  'Rejected',
  'Ghosted',
  'N/A',
  'Offer - evaluating',
  'Offer and I accepted',
  'Offer but I rejected',
];

export const jobApplicationOnOptions = [
  'Company Website',
  'DevBG',
  'JobsBG',
  'Employee Referral',
  'LinkedIn',
  'Recruitement Company',
  'Recruiter reached out',
];

export interface JobSummary {
  id: number;
  company: string;
  role: string;
  status: string;
  date: string;
  applicationType: string;
  outcome: string;
}
