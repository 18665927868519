import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { Button, ListItemButton, Tooltip, Typography } from '@mui/material';
// import { LogoutButton } from 'pages/account/LogoutButton';
// import HelpButton from './buttons/HelpButton';
// import PrivacyButton from './buttons/PrivacyButton';
// import DiscordButton from './buttons/DiscordButton';
// import VSCodeButton from './buttons/VSCodeButton';
// import PremiumButton from './buttons/PremiumButton';
import { AppContext } from 'context/context';
import { useContext, useEffect, useState } from 'react';
import { localStorageKeys, themeColors, apiServerLink } from 'sitevars';
import axios from 'axios';
import { GoogleUserData, UserData } from 'interfaces/interfaces';
import { formatEpochToDate, getUserEmail } from 'utils/Helpers';
import userLogo from 'assets/user_avatar.svg';
import { useAuth } from 'context/AuthContext';
import ExitToAppSharpIcon from '@mui/icons-material/ExitToAppSharp';
import { LogoutButton } from 'components/buttons/LogoutButton';
import DiscordButton from 'components/buttons/DiscordButton';
import PremiumButton from 'components/buttons/PremiumButton';
import DarkLightModeButton from 'components/buttons/DarkLightModeButton';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  padding: 5,
  bgcolor: 'background.paper',
  boxShadow: 10,
  paddingBottom: 5,
};

export default function ProfileModal() {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState<UserData | null>(null);
  const { state, dispatch } = useContext(AppContext);
  const [userAvatar, setUserAvatar] = useState<string | null>(null);
  const { logout } = useAuth();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    logout();
  };

  const membershipType = state.membership.toLowerCase();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const email = getUserEmail(state);
        if (email) {
          if (
            localStorage.getItem(localStorageKeys.userData) === '' ||
            localStorage.getItem(localStorageKeys.userData) === null ||
            localStorage.getItem(localStorageKeys.userData) === undefined
          ) {
            const response = await axios.get(`${apiServerLink}/api/user_data/${email}`);
            const userData = response.data[0];
            setUser(userData);
            localStorage.setItem(localStorageKeys.userData, JSON.stringify(userData));
          } else {
            const storedData = localStorage.getItem(localStorageKeys.userData);
            if (storedData) {
              const userData = JSON.parse(storedData);
              setUser(userData);
            }
          }
          if (
            localStorage.getItem(localStorageKeys.googleUserData) !== '' ||
            localStorage.getItem(localStorageKeys.googleUserData) !== null ||
            localStorage.getItem(localStorageKeys.googleUserData) !== undefined
          ) {
            const storedData = localStorage.getItem(localStorageKeys.googleUserData);
            if (storedData) {
              const googleUser = JSON.parse(storedData) as GoogleUserData;
              setUserAvatar(googleUser.picture);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    fetchUserData();
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <>
        <Tooltip title="Profile" placement="right" arrow>
          <IconButton
            id="profile-button"
            disableRipple
            style={{
              color: '#2196f3',
              fontSize: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={handleOpen}
          >
            {user && userAvatar ? (
              <Avatar alt={user.name} src={userAvatar} style={{ width: '30px', height: '30px' }} />
            ) : (
              <Avatar alt="user avatar" src={userLogo} style={{ width: '30px', height: '30px' }} />
            )}
          </IconButton>
        </Tooltip>

        <Modal
          aria-labelledby="transition-modal-title-avatar"
          aria-describedby="transition-modal-description-avatar"
          open={open}
          onClose={handleClose}
          closeAfterTransition
        >
          <Fade in={open}>
            <Box sx={style}>
              <Typography
                sx={{
                  fontWeight: 300,
                  fontSize: 20,
                  borderBottom: '1px solid #ccc',
                  paddingBottom: 1,
                }}
              >
                User Profile
              </Typography>
              {user ? (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'left',
                      alignItems: 'center',
                      paddingTop: 20,
                      paddingBottom: 20,
                    }}
                  >
                    {userAvatar ? (
                      <Avatar alt={user.name} src={userAvatar} style={{ width: 50, height: 50 }} />
                    ) : (
                      <Avatar alt="user avatar" src={userLogo} style={{ width: 50, height: 50 }} />
                    )}
                    <div>
                      {user.given_name !== '' && (
                        <Typography sx={{ paddingLeft: 2, fontWeight: 400, fontSize: 16 }}>
                          {user.given_name} {user.family_name}
                        </Typography>
                      )}
                      {user.email && (
                        <Typography sx={{ paddingLeft: 2, fontWeight: 400, fontSize: 16 }}>{user.email}</Typography>
                      )}
                    </div>
                  </div>

                  {membershipType && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'left',
                        alignItems: 'flex-start',
                        paddingTop: 0,
                        paddingBottom: 20,
                        backgroundColor: '',
                      }}
                    >
                      {user.start_date && (
                        <Typography
                          sx={{
                            paddingLeft: 0,
                            fontWeight: 300,
                            fontSize: 16,
                            paddingRight: 1,
                          }}
                        >
                          Member since{' '}
                          <span style={{ color: themeColors.muiDarkBlue, fontWeight: 500 }}>
                            {formatEpochToDate(user.start_date)}
                          </span>
                        </Typography>
                      )}
                      {user.lc_user && (
                        <Typography
                          sx={{
                            paddingLeft: 0,
                            fontWeight: 300,
                            fontSize: 16,
                            paddingRight: 1,
                            paddingTop: 1,
                          }}
                        >
                          Leetcode handle{' '}
                          <span style={{ color: themeColors.muiDarkBlue, fontWeight: 500 }}>@{user.lc_user}</span>
                        </Typography>
                      )}
                      {!user.lc_user && (
                        <Typography
                          sx={{
                            paddingLeft: 0,
                            fontWeight: 300,
                            fontSize: 16,
                            paddingRight: 1,
                            paddingTop: 1,
                          }}
                        >
                          Please contact your Administator to connect your Leetcode profile.
                        </Typography>
                      )}
                      <div style={{ paddingTop: 15, fontWeight: 300 }}>
                        <PremiumButton text="premium" /> membership
                      </div>
                      <DarkLightModeButton />
                    </div>
                  )}
                </>
              ) : (
                <Typography sx={{ fontWeight: 300, fontSize: 16, paddingTop: 3 }}>
                  Please log in to access user information.
                </Typography>
              )}

              <div>
                <Typography sx={{ fontWeight: 300, fontSize: 18 }}>Actions</Typography>
                <DiscordButton />
                <LogoutButton handleLogout={handleLogout} />
              </div>

              {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2, marginBottom: 2 }}>
                <Button
                  sx={{
                    marginRight: 2,
                    backgroundColor: themeColors.muiLightBlue,
                    '&:hover': {
                      backgroundColor: themeColors.muiPrimaryBlue,
                    },
                  }}
                  variant="contained"
                  onClick={handleLogout}
                >
                  Log out
                </Button>
              </Box> */}
            </Box>
          </Fade>
        </Modal>
      </>
    </div>
  );
}
