import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AppContext } from 'context/context';
import { localStorageKeys, apiServerLink } from 'sitevars';
import { TestCard } from 'components/TestCard';
import { countTestStatus, getUserId } from 'utils/Helpers';
import Spinner from 'components/common/Spinner';
import { PageHeader } from 'components/common/PageHeader';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { TopicTrackerSectionTitle } from 'components/common/TopicTrackerSectionTitle';
import BarChartVisualization from 'components/charts/BarChartVisualization';
import { getPreviousAndNextTest, TopicNavigationResult } from 'utils/ProblemHelpers';
import { PrevCurrNextCard } from 'components/charts/PrevCurrNextCard';
import { TestProps, UserData } from 'interfaces/interfaces';
import { ChartProps } from 'components/charts/PieChartVisualsization';

function Tests() {
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  const [testChartProps, setTestChartProps] = useState<ChartProps[]>([]);
  const [challenges, setChallenges] = useState<TestProps[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [prevCurrNextTests, setPrevCurrNextTests] = useState<TopicNavigationResult | null>(null);

  const fetchChallenges = async () => {
    try {
      const userId = getUserId(state);

      const response = await axios.get(`${apiServerLink}/api/all_tests?user_id=${userId}`);
      const challenges = response.data;

      // localStorage.setItem(localStorageKeys.testsData, JSON.stringify(challenges));
      setChallenges(challenges);

      const barChartData = countTestStatus(challenges);
      setTestChartProps(barChartData);

      const prevCurrNext = getPreviousAndNextTest(challenges);
      setPrevCurrNextTests(prevCurrNext);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching challenges:', error);
      setError('Error fetching challenges');
      setLoading(false);
    }
  };

  useEffect(() => {
    const data = localStorage.getItem(localStorageKeys.testsData);
    if (data === '' || data === null || data === undefined || data.length <= 0) {
      fetchChallenges();
    } else {
      const challenges = JSON.parse(data);
      setChallenges(challenges);

      const barChartData = countTestStatus(challenges);
      setTestChartProps(barChartData);

      const prevCurrNext = getPreviousAndNextTest(challenges);
      setPrevCurrNextTests(prevCurrNext);

      setLoading(false);
    }
  }, []);

  return (
    <div>
      <Stack spacing={1} sx={{ backgroundColor: '', paddingBottom: 20 }}>
        <Stack spacing={1}>
          <PageHeader title="Tracker Tests" />
          {loading && <Spinner />}
          {!loading && challenges.length === 0 && (
            <Typography>
              No tests available. Please close the app and try to login again. If the issue persists please contact the
              administrator.
            </Typography>
          )}
          {!loading && challenges.length > 0 && (
            <Grid container spacing={2} sx={{ backgroundColor: '' }}>
              <Grid item xs={12} lg={8}>
                <TopicTrackerSectionTitle title="Tests" />
                <Grid container spacing={2}>
                  {challenges.map((challenge) => (
                    <Grid key={challenge.id} item lg={6} md={6} sm={12} xs={12}>
                      <TestCard data={challenge} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12} lg={4}>
                <TopicTrackerSectionTitle title="Statistics" />
                {prevCurrNextTests && <PrevCurrNextCard data={prevCurrNextTests} />}
                <Box
                  sx={{
                    width: '28vw',
                    height: '50vh',
                    backgroundColor: '',
                  }}
                >
                  <BarChartVisualization chartProps={testChartProps} title="" />
                </Box>
              </Grid>
            </Grid>
          )}
        </Stack>
      </Stack>
    </div>
  );
}

export default Tests;
