import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import DataTable from '../components/DataTable';
import Spinner from '../components/common/Spinner';
import { AppContext } from 'context/context';
import { PageHeader } from 'components/common/PageHeader';
import { localStorageKeys, apiServerLink } from 'sitevars';
import { Container, Typography } from '@mui/material';
import programming from 'assets/programming.svg';
import { getUserId } from 'utils/Helpers';

const Tracker = () => {
  const { state, dispatch } = React.useContext(AppContext);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const userId = getUserId(state);
    if (userId) {
      if (
        localStorage.getItem(localStorageKeys.dailyData) === '' ||
        localStorage.getItem(localStorageKeys.dailyData) === null ||
        localStorage.getItem(localStorageKeys.dailyData) === undefined
      ) {
        axios
          .get(`${apiServerLink}/solved_failed_data`, {
            params: { user_id: userId },
          })
          .then((response) => {
            if (response.data && response.data !== undefined && response.data !== '' && response.data.length > 0) {
              setData(response.data);
              // localStorage.setItem(localStorageKeys.dailyData, JSON.stringify(response.data));
            }

            setLoading(false);
          })
          .catch((error) => {
            console.error('There was an error fetching the data for daily logger!', error);
            setLoading(false);
          });
      } else {
        const storedData = localStorage.getItem(localStorageKeys.dailyData);
        if (storedData) {
          const parsedData = JSON.parse(storedData);
          setData(parsedData);
          setLoading(false);
        } else {
          console.error("Couldn't get data from local storage properly for Daily logging");
        }
      }
    } else {
      setLoading(false);
      setError(true);
    }
  }, []);

  return (
    <div>
      <PageHeader title="Daily Logging Tracker" />
      {loading && <Spinner />}
      {!loading && error && (
        <Typography>
          Sorry, something went wrong. Please close the app and try to login again. If the issue persists, please
          contact the administrator.
        </Typography>
      )}
      {!loading && !error && data && data !== undefined && data !== '' && (
        <Container maxWidth="md">
          <DataTable data={data} />
        </Container>
      )}
      {!loading && !error && (!data || data === undefined || data === '') && (
        <>
          <Typography sx={{ padding: 3 }}>
            You don't have any submissions yet. Check here again after you solve a few Leetcode problems.
          </Typography>
          <img src={programming} alt="Logo" className="rotate-left" style={{ width: '300px', padding: 30 }} />
        </>
      )}
    </div>
  );
};

export default Tracker;
