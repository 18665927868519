import React, { useState, useEffect } from 'react';
import '../../style/LoadingDots.css'; // Ensure this path is correct

const LoadingDots = () => {
  const [textIndex, setTextIndex] = useState(0);
  const loadingTexts = ['Pending', 'Judging', 'Processing', 'Assessing', 'Verifying', 'Checking'];

  useEffect(() => {
    const timer = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % loadingTexts.length);
    }, 1500); // Change text every 1.5 seconds

    return () => clearInterval(timer); // Cleanup on component unmount
  }, []);

  return (
    <div className="loading-container">
      {loadingTexts[textIndex]}
      <span className="dot">.</span>
      <span className="dot">.</span>
      <span className="dot">.</span>
    </div>
  );
};

export default LoadingDots;
