import Typography from '@mui/material/Typography';
import { strings } from 'strings';

export default function NotificationSection() {
  return (
    <div
      style={{
        backgroundColor: 'white',
        borderTopLeftRadius: '15px',
        borderBottomLeftRadius: '20px',
        paddingLeft: 10,
        paddingRight: 10,
        height: '99vh',
      }}
    >
      <Typography
        style={{
          paddingTop: 15,
          paddingBottom: 10,
          display: 'flex',
          alignItems: 'center',
          fontSize: 20,
          fontWeight: 'bold',
          color: 'black',
        }}
      >
        {strings.secondarySectionSidebarTitle}
      </Typography>
      <Typography style={{ paddingBottom: 10, fontWeight: '200', color: 'black' }}>
        You don&apos;t have any notifications at the moment.
      </Typography>
    </div>
  );
}
