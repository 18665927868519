import { Box, IconButton, Rating, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { Formik, Form, Field, FieldArray, FormikErrors, FormikTouched, FieldProps, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { TextField, MenuItem, Button } from '@mui/material';
import { Select, InputLabel, FormControl } from '@mui/material';
import { useParams } from 'react-router-dom';
import { PageHeader } from './common/PageHeader';
import {
  jobApplicationOnOptions,
  jobApplicationOutcome,
  jobStageOptions,
  jobStatusOptions,
  JobSummary,
} from 'interfaces/interfaces';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveSharpIcon from '@mui/icons-material/SaveSharp';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { AppContext } from 'context/context';
import { apiServerLink, themeColors } from 'sitevars';
import { generateId, getUserId } from 'utils/Helpers';
import { NotificationTypeEnum } from 'strings';
import ToastAction from './common/ToastAction';

export default function JobCard() {
  const { job_id } = useParams<{ job_id: string }>();
  const jobIdNumber = Number(job_id);
  const theme = useTheme();
  const { state } = useContext(AppContext);
  const [showoffer, setShowoffer] = useState<boolean>(false);
  const [showInterviewRefclection, setShowhowInterviewRefclection] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<FormValues>({
    jobSummary: {
      id: jobIdNumber,
      company: '',
      role: '',
      status: '',
      date: '',
      applicationType: '',
      outcome: '',
    },
    stages: [],
    offer: { date: '', base: '', bonus: '', equity: '', notes: '' },
    interviewReflection: { rating: '', notes: '' },
  });
  //notification toast vars
  const [notification, setNotification] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>('');
  const [notificationType, setNotificationType] = useState<
    typeof NotificationTypeEnum[keyof typeof NotificationTypeEnum]
  >(NotificationTypeEnum.INFO);

  const fetchJob = async () => {
    try {
      const userId = getUserId(state);
      const response = await axios.get(`${apiServerLink}/api/get_job/${userId}/${jobIdNumber}`);
      const jobData = transformJobData(response.data);
      setInitialValues(jobData);
      if (jobData.interviewReflection.notes !== '' || jobData.interviewReflection.rating !== '') {
        setShowhowInterviewRefclection(true);
      }
      if (
        jobData.offer.base !== '' ||
        jobData.offer.bonus !== '' ||
        jobData.offer.date !== '' ||
        jobData.offer.equity !== '' ||
        jobData.offer.notes !== ''
      ) {
        setShowoffer(true);
      }
    } catch (error) {
      console.error('Error fetching jobs data:', error);
    }
  };

  useEffect(() => {
    if (jobIdNumber) {
      fetchJob();
    }
  }, []);

  const removeInterviewRefclection = (submitForm: () => void) => {
    setShowhowInterviewRefclection(false);

    const updatedInterviewReflection = {
      rating: '',
      notes: '',
    };

    setInitialValues((prevValues) => ({
      ...prevValues,
      interviewReflection: updatedInterviewReflection,
    }));

    submitForm();
  };

  const removeOffer = (submitForm: () => void) => {
    setShowoffer(false);

    const updatedOfferDetails = { date: '', base: '', bonus: '', equity: '', notes: '' };

    setInitialValues((prevValues) => ({
      ...prevValues,
      offer: updatedOfferDetails,
    }));

    submitForm();
  };

  const removeStage = async (stage: StageFormValues, index: number, remove: any) => {
    try {
      const userId = getUserId(state);
      if (initialValues.jobSummary.id && stage.id) {
        const response = await axios.delete(
          `${apiServerLink}/api/delete_job_stage/${userId}/${initialValues.jobSummary.id}/${stage.id}`
        );
        if (response.status === 200) {
          // notification for success
          setNotification(true);
          setNotificationText('Job updated successfully!');
          setNotificationType(NotificationTypeEnum.SUCCESS);
          remove(index);
        } else {
          // notification for error
          setNotification(true);
          setNotificationText('Job did not update successfully. Please try again.');
          setNotificationType(NotificationTypeEnum.ERROR);
        }
      }
    } catch (error) {
      // console.error('Error while deleting the stage:', error);
      // notification for error
      setNotification(true);
      setNotificationText('Job did not update successfully. Please try again.');
      setNotificationType(NotificationTypeEnum.ERROR);
    }
  };

  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    handleUpdateFormValues(values);
    setSubmitting(false);
  };

  const handleUpdateFormValues = async (values: FormValues) => {
    const dbData = reverseTransformJobDataForDB(values);
    const userId = getUserId(state);
    const response = await axios.put(`${apiServerLink}/api/update_job/${userId}/${dbData.id}`, dbData);
    if (response.status === 200) {
      // notification for success
      setNotification(true);
      setNotificationText('Job updated successfully!');
      setNotificationType(NotificationTypeEnum.SUCCESS);
    } else {
      // notification for error
      setNotification(true);
      setNotificationText('Job did not update successfully. Please try again.');
      setNotificationType(NotificationTypeEnum.ERROR);
    }
  };

  const handleCloseNotification = () => {
    setNotification(false);
    setNotificationText('');
    setNotificationType(NotificationTypeEnum.INFO);
  };

  const pageTitleIntro = 'Job Application';
  const [pageTitle, setPageTitle] = useState(pageTitleIntro);
  // const { values } = useFormikContext<FormValues>();
  // useEffect(() => {
  //   const handleFormValuesChange = (values: FormValues) => {
  //     const updatedTitle = pageTitleIntro + ' ' + values.jobSummary.company;
  //     setPageTitle(updatedTitle);
  //   };

  //   handleFormValuesChange(values);
  // }, [values]);

  return (
    <>
      {notification && (
        <ToastAction
          message={notificationText}
          type={notificationType}
          open={notification}
          close={handleCloseNotification}
        />
      )}

      <div>
        <Stack spacing={1} sx={{ backgroundColor: '', paddingBottom: 0 }}>
          <PageHeader title={pageTitle} />
          <Box sx={{ width: '50vw' }}>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              // validationSchema={validationSchema} //this is breaking the flow and not really needed
              onSubmit={handleSubmit}
            >
              {({ values, handleChange, handleBlur, touched, errors, isSubmitting, setFieldValue, submitForm }) => (
                <Form>
                  {/* JOB SUMMMARY SECTION */}
                  <Box mb={2} p={2} border={1} borderRadius="10px" borderColor={themeColors.dimGrey}>
                    <Typography sx={{ fontSize: 16, fontWeight: 500, mb: 1 }}>Application Summary</Typography>
                    {/* Company Field */}
                    <div>
                      <Field
                        name="jobSummary.company"
                        as={TextField}
                        label="Company"
                        fullWidth
                        margin="normal"
                        error={touched.jobSummary?.company && Boolean(errors.jobSummary?.company)}
                        helperText={touched.jobSummary?.company && errors.jobSummary?.company}
                      />
                    </div>

                    {/* Role Field */}
                    <div>
                      <Field
                        name="jobSummary.role"
                        as={TextField}
                        label="Role"
                        fullWidth
                        margin="normal"
                        error={touched.jobSummary?.role && Boolean(errors.jobSummary?.role)}
                        helperText={touched.jobSummary?.role && errors.jobSummary?.role}
                      />
                    </div>

                    {/* Status Field */}
                    <div>
                      <FormControl fullWidth margin="normal">
                        <InputLabel>Status</InputLabel>
                        <Field
                          name="jobSummary.status"
                          as={Select}
                          label="Status"
                          error={touched.jobSummary?.status && Boolean(errors.jobSummary?.status)}
                        >
                          {jobStatusOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Field>
                        {touched.jobSummary?.status && errors.jobSummary?.status ? (
                          <div>{errors.jobSummary?.status}</div>
                        ) : null}
                      </FormControl>
                    </div>

                    {/* Application Date Field */}
                    <div>
                      <Field
                        name="jobSummary.date"
                        as={TextField}
                        type="date"
                        label="Application Date"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        error={touched.jobSummary?.date && Boolean(errors.jobSummary?.date)}
                        helperText={touched.jobSummary?.date && errors.jobSummary?.date}
                      />
                    </div>

                    {/* Applied On Field */}
                    <div>
                      <FormControl fullWidth margin="normal">
                        <InputLabel>Application Type</InputLabel>
                        <Field
                          name="jobSummary.applicationType"
                          as={Select}
                          label="Application Type"
                          error={touched.jobSummary?.applicationType && Boolean(errors.jobSummary?.applicationType)}
                        >
                          {jobApplicationOnOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Field>
                        {touched.jobSummary?.applicationType && errors.jobSummary?.applicationType ? (
                          <div>{errors.jobSummary?.applicationType}</div>
                        ) : null}
                      </FormControl>
                    </div>

                    {/* Outcomme Field */}
                    <div>
                      <FormControl fullWidth margin="normal">
                        <InputLabel>Outcome</InputLabel>
                        <Field
                          name="jobSummary.outcome"
                          as={Select}
                          label="Outcome"
                          error={touched.jobSummary?.outcome && Boolean(errors.jobSummary?.outcome)}
                        >
                          {jobApplicationOutcome.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Field>
                        {touched.jobSummary?.outcome && errors.jobSummary?.outcome ? (
                          <div>{errors.jobSummary?.outcome}</div>
                        ) : null}
                      </FormControl>
                    </div>

                    <Tooltip title="Save">
                      <IconButton onClick={submitForm} aria-label="save" color="primary">
                        <SaveSharpIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>

                  {/* JOB INTERVIEW STAGES SECTION */}
                  <FieldArray name="stages">
                    {({ push, remove }) => (
                      <div>
                        {values.stages.map((stage, index) => (
                          <Box
                            key={index}
                            mb={2}
                            p={2}
                            border={1}
                            borderRadius="10px"
                            borderColor={themeColors.dimGrey}
                          >
                            <Typography sx={{ fontSize: 16, fontWeight: 500, mb: 1 }}>
                              Stage {index + 1} Summary
                            </Typography>
                            <div>
                              <FormControl fullWidth margin="normal">
                                <InputLabel>Stage</InputLabel>
                                <Field
                                  name={`stages[${index}].stage`}
                                  as={Select}
                                  label="Stage"
                                  error={
                                    (touched.stages as FormikTouched<StageFormValues>[] | undefined)?.[index]?.stage &&
                                    Boolean(
                                      (errors.stages as FormikErrors<StageFormValues>[] | undefined)?.[index]?.stage
                                    )
                                  }
                                >
                                  {jobStageOptions.map((option) => (
                                    <MenuItem key={option} value={option}>
                                      {option}
                                    </MenuItem>
                                  ))}
                                </Field>
                                {touched.stages?.[index] &&
                                (errors.stages as FormikErrors<StageFormValues>[] | undefined)?.[index]?.stage ? (
                                  <div>{(errors.stages as FormikErrors<StageFormValues>[])[index]?.stage}</div>
                                ) : null}
                              </FormControl>
                            </div>

                            <div>
                              <Field
                                name={`stages[${index}].stageDetails`}
                                as={TextField}
                                label="Stage Details"
                                multiline
                                minRows={3}
                                fullWidth
                                margin="normal"
                                error={
                                  (touched.stages as FormikTouched<StageFormValues>[] | undefined)?.[index]
                                    ?.stageDetails &&
                                  Boolean(
                                    (errors.stages as FormikErrors<StageFormValues>[] | undefined)?.[index]
                                      ?.stageDetails
                                  )
                                }
                                helperText={
                                  (touched.stages as FormikTouched<StageFormValues>[] | undefined)?.[index]
                                    ?.stageDetails &&
                                  (errors.stages as FormikErrors<StageFormValues>[] | undefined)?.[index]?.stageDetails
                                }
                              />
                            </div>

                            <div>
                              <Field
                                name={`stages[${index}].date`}
                                as={TextField}
                                type="date"
                                label="Stage Date"
                                fullWidth
                                margin="normal"
                                InputLabelProps={{ shrink: true }}
                                error={
                                  (touched.stages as FormikTouched<StageFormValues>[] | undefined)?.[index]?.date &&
                                  Boolean((errors.stages as FormikErrors<StageFormValues>[] | undefined)?.[index]?.date)
                                }
                                helperText={
                                  (touched.stages as FormikTouched<StageFormValues>[] | undefined)?.[index]?.date &&
                                  (errors.stages as FormikErrors<StageFormValues>[] | undefined)?.[index]?.date
                                }
                              />
                            </div>

                            {/* File Upload Field */}
                            {/* <div>
                            <input
                              id={`file-${index}`}
                              name={`stages[${index}].file`}
                              type="file"
                              onChange={(event) => {
                                if (event.currentTarget.files && event.currentTarget.files[0]) {
                                  setFieldValue(`stages[${index}].file`, event.currentTarget.files[0]);
                                }
                              }}
                            />
                          </div> */}

                            <Tooltip title="Delete">
                              <IconButton
                                onClick={() => removeStage(stage, index, remove)}
                                aria-label="delete"
                                sx={{ color: themeColors.lightGrey }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Save">
                              <IconButton onClick={submitForm} aria-label="save" color="primary">
                                <SaveSharpIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        ))}

                        {/* JOB OFFER SECTION */}
                        {showoffer && (
                          <Box mt={2} p={2} border={1} borderRadius="10px" borderColor={themeColors.dimGrey}>
                            <Typography sx={{ fontSize: 16, fontWeight: 500, mb: 1 }}>Offer Details</Typography>

                            {/* Offer Date Field */}
                            <div>
                              <Field
                                name="offer.date"
                                as={TextField}
                                type="date"
                                label="Offer Date"
                                fullWidth
                                margin="normal"
                                InputLabelProps={{ shrink: true }}
                                error={touched.offer?.date && Boolean(errors.offer?.date)}
                                helperText={touched.offer?.date && errors.offer?.date}
                              />
                            </div>

                            <div>
                              <Field
                                name="offer.base"
                                as={TextField}
                                label="Base Salary"
                                fullWidth
                                margin="normal"
                                error={touched.offer?.base && Boolean(errors.offer?.base)}
                                helperText={touched.offer?.base && errors.offer?.base}
                              />
                            </div>

                            <div>
                              <Field
                                name="offer.bonus"
                                as={TextField}
                                label="Bonus"
                                fullWidth
                                margin="normal"
                                error={touched.offer?.bonus && Boolean(errors.offer?.bonus)}
                                helperText={touched.offer?.bonus && errors.offer?.bonus}
                              />
                            </div>

                            <div>
                              <Field
                                name="offer.equity"
                                as={TextField}
                                label="Equity"
                                fullWidth
                                margin="normal"
                                error={touched.offer?.equity && Boolean(errors.offer?.equity)}
                                helperText={touched.offer?.equity && errors.offer?.equity}
                              />
                            </div>

                            <div>
                              <Field
                                name="offer.notes"
                                as={TextField}
                                label="Notes"
                                multiline
                                minRows={3}
                                fullWidth
                                margin="normal"
                                helperText={touched.offer?.notes && errors.offer?.notes}
                              />
                            </div>
                            <Tooltip title="Delete">
                              <IconButton
                                onClick={() => removeOffer(submitForm)}
                                aria-label="delete"
                                sx={{ color: themeColors.lightGrey }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Save">
                              <IconButton onClick={submitForm} aria-label="save" color="primary">
                                <SaveSharpIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        )}

                        {/* INTERVIEW REFLECTON SECTION */}
                        {showInterviewRefclection && (
                          <Box mt={2} p={2} border={1} borderRadius="10px" borderColor={themeColors.dimGrey}>
                            <Typography sx={{ fontSize: 16, fontWeight: 500, mb: 1 }}>Interview Reflection</Typography>

                            {/* Rating Field */}
                            <div>
                              <InputLabel>My personal rating how well I performed in the interview</InputLabel>
                              <Field
                                name="interviewReflection.rating"
                                render={({ field }: FieldProps) => (
                                  <Rating
                                    {...field}
                                    name="interviewReflection.rating"
                                    value={parseInt(field.value) || 0}
                                    onChange={(event, newValue) => {
                                      field.onChange(event); // trigger form change
                                      field.value = newValue; // set rating value
                                    }}
                                  />
                                )}
                              />
                              {touched.interviewReflection?.rating && errors.interviewReflection?.rating ? (
                                <div>{errors.interviewReflection?.rating}</div>
                              ) : null}
                            </div>

                            {/* Notes Field */}
                            <div>
                              <Field
                                name="interviewReflection.notes"
                                as={TextField}
                                label="Reflection Notes"
                                multiline
                                minRows={3}
                                fullWidth
                                margin="normal"
                                helperText={touched.interviewReflection?.notes && errors.interviewReflection?.notes}
                              />
                            </div>

                            <Tooltip title="Delete">
                              <IconButton
                                onClick={() => removeInterviewRefclection(submitForm)}
                                aria-label="delete"
                                sx={{ color: themeColors.lightGrey }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Save">
                              <IconButton onClick={submitForm} aria-label="save" color="primary">
                                <SaveSharpIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        )}

                        {/* ACTION BUTTONS SECTION */}
                        <Box sx={{ marginTop: 2 }}>
                          <Button
                            sx={{
                              textTransform: 'none',
                              backgroundColor: theme.palette.primary.light,
                              margin: 0,
                              padding: 0,
                              borderRadius: 30,
                              paddingX: 1,
                              fontWeight: 0,
                              marginBottom: 1,
                              marginRight: 1,
                            }}
                            onClick={() => push({ stage: '', stageDetails: '', date: '', id: generateId() })}
                          >
                            Add Interview Stage
                          </Button>
                          {!showoffer && (
                            <Button
                              sx={{
                                textTransform: 'none',
                                backgroundColor: theme.palette.primary.light,
                                margin: 0,
                                padding: 0,
                                borderRadius: 30,
                                paddingX: 1,
                                fontWeight: 0,
                                marginBottom: 1,
                                marginRight: 1,
                              }}
                              onClick={() => {
                                setShowoffer(!showoffer);
                              }}
                            >
                              Add Job Offer Details
                            </Button>
                          )}

                          {!showInterviewRefclection && (
                            <Button
                              sx={{
                                textTransform: 'none',
                                backgroundColor: theme.palette.primary.light,
                                margin: 0,
                                padding: 0,
                                borderRadius: 30,
                                paddingX: 1,
                                fontWeight: 0,
                                marginBottom: 1,
                              }}
                              onClick={() => {
                                setShowhowInterviewRefclection(!showInterviewRefclection);
                              }}
                            >
                              Add Job Application Reflection
                            </Button>
                          )}
                        </Box>
                      </div>
                    )}
                  </FieldArray>

                  {/* Submit Button */}
                  <div style={{ marginTop: '20px', marginBottom: '40px' }}>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      sx={{
                        textTransform: 'none',
                        backgroundColor: themeColors.muiLightBlue,
                        color: theme.palette.primary.light,
                        margin: 0,
                        padding: 0,
                        borderRadius: 30,
                        paddingX: 1,
                        fontWeight: 0,
                        marginBottom: 1,
                        '&:hover': {
                          color: themeColors.muiPrimaryBlue,
                        },
                      }}
                    >
                      Update job application
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Box>
        </Stack>
      </div>
    </>
  );
}

// const validationSchema = Yup.object({
// jobSummary: Yup.object().shape({
//   company: Yup.string().required('Company is required'),
//   role: Yup.string().required('Role is required'),
//   status: Yup.string().required('Status is required'),
//   date: Yup.date().required('Application Date is required'),
//   applicationType: Yup.string().required('Current Stage is required'),
//   outcome: Yup.string().notRequired(),
// }),
// stages: Yup.array().of(
//   Yup.object({
//     stage: Yup.string().notRequired(),
//     stageDetails: Yup.string().notRequired(),
//     date: Yup.date().notRequired(),
//     file: Yup.mixed().notRequired(),
//   })
// ),
// offer: Yup.object().shape({
//   base: Yup.string().notRequired(),
//   bonus: Yup.string().notRequired(),
//   equity: Yup.string().notRequired(),
//   notes: Yup.string().notRequired(),
// }),
// interviewReflection: Yup.object({
//   rating: Yup.number().notRequired(),
//   notes: Yup.string().notRequired(),
// }),
// });

interface FormValues {
  jobSummary: JobSummary;
  stages: StageFormValues[];
  offer: offer;
  interviewReflection: InterviewReflection;
}

interface StageFormValues {
  id: number;
  stage: string;
  stageDetails: string;
  date: string;
}
interface offer {
  date: string;
  base: string;
  bonus: string;
  equity: string;
  notes: string;
}

interface InterviewReflection {
  rating: string;
  notes: string;
}

const transformJobData = (data: any) => {
  const { job, stages } = data;

  return {
    jobSummary: {
      id: job.id,
      company: job.company || '',
      role: job.role || '',
      status: job.status || '',
      date: job.date ? new Date(job.date).toISOString().split('T')[0] : '',
      applicationType: job.application_type || '',
      outcome: job.outcome || '',
    },
    offer: {
      date: job.offer_date ? new Date(job.offer_date).toISOString().split('T')[0] : '',
      base: job.offer_base || '',
      bonus: job.offer_bonus || '',
      equity: job.offer_equity || '',
      notes: job.offer_notes || '',
    },
    interviewReflection: {
      rating: job.interview_reflection_rating || '',
      notes: job.interview_reflection_notes || '',
    },
    stages: stages.map((stage: any) => ({
      id: stage.id || generateId(),
      stage: stage.name || '',
      stageDetails: stage.details || '',
      date: stage.date ? new Date(stage.date).toISOString().split('T')[0] : '',
    })),
  };
};

const reverseTransformJobDataForDB = (formValues: FormValues) => {
  return {
    id: formValues.jobSummary.id,
    company: formValues.jobSummary.company,
    role: formValues.jobSummary.role,
    status: formValues.jobSummary.status,
    date: formValues.jobSummary.date ? new Date(formValues.jobSummary.date).toISOString() : null,
    application_type: formValues.jobSummary.applicationType,
    outcome: formValues.jobSummary.outcome,

    offer_date: formValues.offer.date ? new Date(formValues.offer.date).toISOString() : null,
    offer_base: formValues.offer.base,
    offer_bonus: formValues.offer.bonus,
    offer_equity: formValues.offer.equity,
    offer_notes: formValues.offer.notes,

    interview_reflection_rating: formValues.interviewReflection.rating,
    interview_reflection_notes: formValues.interviewReflection.notes,

    stages: formValues.stages.map((stage) => ({
      id: stage.id,
      name: stage.stage,
      date: stage.date ? new Date(stage.date).toISOString() : null,
      details: stage.stageDetails,
    })),
  };
};
