import { Box, Divider, Typography } from '@mui/material';
import React from 'react';

interface TopicTrackerSectionTitleProps {
  title: string;
}

export const TopicTrackerSectionTitle: React.FC<TopicTrackerSectionTitleProps> = ({ title }) => {
  return (
    <Box sx={{ marginBottom: 2, backgroundColor: '' }}>
      <Typography variant="h5">{title}</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
        <Divider sx={{ flexGrow: 1 }} />
      </Box>
    </Box>
  );
};
