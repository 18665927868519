import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import BoltSharpIcon from '@mui/icons-material/BoltSharp'
import BorderColorSharpIcon from '@mui/icons-material/BorderColorSharp'
import {  TopicMetadata, TopicStatus, TopicStatusTypes } from 'interfaces/interfaces'
import { useNavigate } from 'react-router-dom'
import { routes, themeColors } from 'sitevars'
import { Box, Button, Tooltip } from '@mui/material'
import { getDifficultyIconColor, getTopicStatusColor, mapTopicStatus, mapTopicStatusToPointer } from 'utils/Helpers'

const topicStatussMetadata = [
  {
    'backtracking' : {
          title: 'Backtracking',
    description:
      'GitHub is a web-based hosting service for version control of code using Git.',
 
    }
 },
  {
    'dynamic-programming': {
    title: 'Dynamic Programming',
    description:
      'Squarespace provides software as a service for website building and hosting. Headquartered in NYC.',
    }

  },
] satisfies TopicMetadata[];

interface TopicCardProps {
  topicStatus: TopicStatus;
}

export default function TargetCard({ topicStatus }: TopicCardProps) {
  const navigate = useNavigate();

  const difficultyColor = getDifficultyIconColor(topicStatus.difficulty);
  const statusColor = getTopicStatusColor(topicStatus.status);
  const statusText = mapTopicStatus(topicStatus.status)
  const topicPointer = mapTopicStatusToPointer(topicStatus.status)
  const isAccessible = topicStatus.status !== TopicStatusTypes.FUTURE

  const openTopic = () => {
    const topic = topicStatus.topic
    const difficulty = topicStatus.difficulty
    navigate(`${routes.targets}/${topic}/${difficulty}`);
  }

  return (

    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
      onClick={isAccessible ? openTopic : undefined}
    >
      <CardContent sx={{ 
            flex: '1 1 auto', 
            cursor: topicPointer, 
            paddingTop: 5, 
            transition: 'background-color 0.3s ease',
            '&:hover': {
              backgroundColor: statusColor 
            }
          }}>
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Typography align="center" variant="h5">
                {topicStatus.topic.replace(/-/g, ' ').toUpperCase()}
            </Typography>
            <Typography align="center" variant="body1">
              {/* {topicStatus.description} */}
              This topic covers a variety of tasks from the {topicStatus.topic.replace(/-/g, ' ')} learning path.
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
      <Divider />
      <Stack
        direction="row"
        spacing={2}
        sx={{ alignItems: 'center', justifyContent: 'space-between', p: 2 }}
      >
        <Stack sx={{ alignItems: 'center' }} direction="row" spacing={1}>
          <BoltSharpIcon sx={{ fontSize: 20, color: themeColors.dimGrey }}/>
          <Typography color="text.secondary" display="inline" variant="body2">
            Difficulty: 
          </Typography>
          <Box
            sx={{
              backgroundColor: difficultyColor,
              borderRadius: '8px',
              padding: '1px',
              paddingX: '15px',
              display: 'inline-block',
            }}
          >
            <Typography variant="body1" sx={{ color: 'white' }}>
              {topicStatus.difficulty}
            </Typography>
          </Box>
        </Stack>
        <Stack sx={{ alignItems: 'center' }} direction="row" spacing={1}>
          {/* <BorderColorSharpIcon /> */}
          <Typography color="text.secondary" display="inline" variant="body2">
            Status: 
          </Typography>
          <Box
            sx={{
              backgroundColor: statusColor,
              borderRadius: '8px',
              padding: '1px',
              paddingX: '15px',
              display: 'inline-block',
            }}
          >
            <Typography variant="body1" sx={{ color: "white" }}>
              {statusText}
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Card>
  )
}
