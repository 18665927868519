import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { googleAuthClientId } from 'sitevars';
import { AuthProvider } from 'context/AuthContext';
import { GlobalThemeProvider } from 'context/GlobalThemeProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.Fragment>
    <BrowserRouter>
      <GoogleOAuthProvider clientId="132793847279-av3bo3o73ehf2q83mndmmbphm7a58vh7.apps.googleusercontent.com">
        <AuthProvider>
          <GlobalThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>            
              <App />
              </LocalizationProvider>
          </GlobalThemeProvider>
        </AuthProvider>
      </GoogleOAuthProvider>
    </BrowserRouter>
  </React.Fragment>
);

reportWebVitals();
