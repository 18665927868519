import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import BorderColorSharpIcon from '@mui/icons-material/BorderColorSharp';
import { Box } from '@mui/material';
import type { SxProps } from '@mui/material/styles';
import { themeColors } from 'sitevars';

export interface TopicSummaryCardProps {
  title?: string;
  subTitle?: string;
  progressValue?: number;
  showProgressBar?: boolean;
  sx?: SxProps;
  icon?: React.ReactNode;
  iconColor?: string;
}

export function TopicSummaryCard({
  title,
  subTitle,
  progressValue,
  showProgressBar,
  sx,
  icon,
  iconColor = themeColors.lightGrey,
}: TopicSummaryCardProps): React.JSX.Element {
  return (
    <Card sx={sx}>
      <CardContent>
        <Stack spacing={2}>
          <Stack direction="row" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
            <Stack spacing={1}>
              <Typography color="text.secondary" gutterBottom variant="overline">
                {title}
              </Typography>
              <Typography variant="h5">{subTitle}</Typography>
            </Stack>
            {icon ? (
              <Avatar sx={{ backgroundColor: iconColor, height: '56px', width: '56px' }}>{icon}</Avatar>
            ) : (
              <Box sx={{ height: '56px', width: '56px' }}></Box>
            )}
          </Stack>
          {showProgressBar && (
            <div>
              <LinearProgress value={progressValue} variant="determinate" />
            </div>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
