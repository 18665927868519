import * as React from 'react';
import TargetCard from 'components/TargetCard';
import PieChartVisualsization from 'components/charts/PieChartVisualsization';
import { AppContext } from 'context/context';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Problem, ProblemSolvedStatus, TopicStatus } from 'interfaces/interfaces';
import { createTopicStatusMap, filterDataByTopicAndDifficulty, transformRawData } from 'utils/ProblemHelpers';
import { localStorageKeys, themeColors } from 'sitevars';
import { useParams } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Box,
  Stack,
  Grid,
} from '@mui/material';

interface TrackerTableProps {
  filteredData: Problem[];
}

export default function TrackerTable({ filteredData }: TrackerTableProps) {
  return (
    <TableContainer component={Paper} style={{ maxHeight: '70vh' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell style={{ minWidth: 10 }}>Count</TableCell>
            <TableCell style={{ minWidth: 150 }}>Title</TableCell>
            <TableCell style={{ minWidth: 100 }}>AC Rate</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map((row, index) => (
            <TableRow key={row.titleSlug}>
              <TableCell>{index + 1}</TableCell>
              <TableCell
                style={{
                  backgroundColor:
                    row.solve_status === ProblemSolvedStatus.SOLVED
                      ? themeColors.lightGreen
                      : row.solve_status === ProblemSolvedStatus.FAILED
                      ? themeColors.lightRed
                      : themeColors.lightGrey,
                  color: 'white',
                }}
              >
                <a
                  href={`https://leetcode.com/problems/${row.titleSlug}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: 'inherit',
                    textDecoration: 'none',
                  }}
                >
                  {row.title}
                </a>
              </TableCell>
              <TableCell>{row.acRate.toFixed(2)}%</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
