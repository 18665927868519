import React from 'react';
import { Card, CardContent, Typography, useTheme } from '@mui/material';
import { themeColors } from 'sitevars';

const QuoteCard = () => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        width: '30vw',
        margin: 'auto',
        mt: 5,
        p: 2,
        backgroundColor: theme.palette.background.default,
        borderRadius: 2,
      }}
    >
      <CardContent>
        <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', mb: 2, color: theme.palette.text.primary }}>
          "Success is no accident."
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mb: 2, color: theme.palette.text.primary }}>
          It is hard work, perseverance, learning, studying, sacrifice, and most of all, love of what you are doing or
          learning to do.
        </Typography>
        {/* <Typography variant="body2" color="text.secondary" align="right">
          – Pelé
        </Typography> */}
      </CardContent>
    </Card>
  );
};

export default QuoteCard;
