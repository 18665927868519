import { createTheme } from '@mui/material/styles';
import { themeColors } from 'sitevars';
import { CssBaseline } from '@mui/material';

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1976d2',
      light: '#dff0ff',
    },
    background: {
      default: '#ffffff',
      paper: '#f6f6f6',
    },
    text: {
      primary: '#000000',
      secondary: '#555555',
    },
    action: {
      active: themeColors.greyIcons,
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
      light: themeColors.grey400,
    },
    background: {
      default: themeColors.grey600,
      paper: themeColors.grey500,
    },
    text: {
      primary: '#ffffff',
      secondary: '#aaaaaa',
    },
    action: {
      active: '#ffffff',
    },
  },
});
