import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import AceEditor from 'react-ace';
import axios from 'axios';
import { PageHeader } from 'components/common/PageHeader';
import { TestData } from 'interfaces/interfaces';
import React, { useEffect, useState } from 'react';
import { apiServerLink, themeColors } from 'sitevars';

function AdminTestGrading() {
  const [testsToDo, setTestsToDo] = useState<TestData[]>([]);
  const [gradedTests, setGradedTests] = useState<TestData[]>([]);
  const [selectedTestData, setSelectedTestData] = useState<TestData | null>(null);
  const [selectedDropdown, setSelectedDropdown] = useState<'todo' | 'graded' | null>(null);

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  useEffect(() => {
    axios
      .get<TestData[]>(`${apiServerLink}/api/tests/need_grading`)
      .then((response) => {
        const data = response.data;
        setTestsToDo(data.filter((item) => item.results === null));
        setGradedTests(data.filter((item) => item.results !== null));
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  const handleDropdownChange = (dropdown: 'todo' | 'graded') => (event: any) => {
    const index = event.target.value as number;

    setSelectedDropdown(dropdown);

    if (dropdown === 'todo' && index >= 0 && index < testsToDo.length) {
      setSelectedIndex(index);
      setSelectedTestData(testsToDo[index]);
    } else if (dropdown === 'graded' && index >= 0 && index < gradedTests.length) {
      setSelectedIndex(index);
      setSelectedTestData(gradedTests[index]);
    } else {
      setSelectedTestData(null);
    }
  };

  const handleFeedbackChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFeedback = event.target.value || null;

    setSelectedTestData((prevState) => {
      if (prevState) {
        return {
          ...prevState,
          feedback: newFeedback,
        };
      }
      return prevState;
    });
  };

  const handleGradeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const newGrade = value === '' ? null : Number(value);

    setSelectedTestData((prevState) => {
      if (prevState) {
        return {
          ...prevState,
          results: newGrade,
        };
      }
      return prevState;
    });
  };

  const handleSubmit = async () => {
    try {
      const res = await axios.post(`${apiServerLink}/api/tests/publish_grade`, {
        // email: selectedTestData?.email,
        user_id: selectedTestData?.user_id,
        difficulty: selectedTestData?.difficulty,
        topic: selectedTestData?.topicTags,
        results: selectedTestData?.results,
        feedback: selectedTestData?.feedback,
      });
      if (res.status === 200) {
        alert('Successfully stored feedback.');
      } else {
        alert('Failed to save test feedback for somem reason. Please try again.');
      }
    } catch (error) {
      console.error('Error while submitting the feedback for the test:', error);
      alert('Failed to save test feedback.');
    }
  };

  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 2fr', gap: 2, p: 2, backgroundColor: '', width: '100%' }}>
      <Stack spacing={1} sx={{ gridColumn: '1 / 2' }}>
        <PageHeader title="Admin Grade Tests" />
        {gradedTests.length > 0 && (
          <FormControl fullWidth>
            <InputLabel id="graded-dropdown-label">Select a Graded test</InputLabel>
            <Select
              labelId="graded-dropdown-label"
              id="graded-dropdown"
              value={selectedDropdown === 'graded' ? selectedIndex : ''}
              label="Select a Graded test"
              onChange={handleDropdownChange('graded')}
            >
              {gradedTests.map((item, index) => (
                <MenuItem key={index} value={index} sx={{}}>
                  {item.name} - {item.difficulty} - {item.topicTags}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {testsToDo.length > 0 && (
          <FormControl fullWidth>
            <InputLabel id="todo-dropdown-label">Select a TODO test</InputLabel>
            <Select
              labelId="todo-dropdown-label"
              id="todo-dropdown"
              value={selectedDropdown === 'todo' ? selectedIndex : ''}
              label="Select a TODO test"
              onChange={handleDropdownChange('todo')}
            >
              {testsToDo.map((item, index) => (
                <MenuItem key={index} value={index} sx={{}}>
                  {item.name} - {item.difficulty} - {item.topicTags}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {selectedTestData && (
          <Box sx={{ border: 1, borderColor: themeColors.lightGrey, padding: 2 }}>
            <Typography variant="h5" sx={{ paddingBottom: 1 }}>
              Test Review:
            </Typography>
            <Typography>
              Name: <span style={{ fontWeight: 'bold' }}>{selectedTestData.name}</span>
            </Typography>
            <Typography>
              LC: <span style={{ fontWeight: 'bold' }}>{selectedTestData.lc_user_x}</span>
            </Typography>
            <Typography>
              Email: <span style={{ fontWeight: 'bold' }}>{selectedTestData.email}</span>
            </Typography>
            <Typography>
              Sprint: <span style={{ fontWeight: 'bold' }}>{selectedTestData.sprints}</span>
            </Typography>
            <Typography>
              Difficulty: <span style={{ fontWeight: 'bold' }}>{selectedTestData.difficulty}</span>
            </Typography>
            <Typography>
              Topic: <span style={{ fontWeight: 'bold' }}>{selectedTestData.topicTags}</span>
            </Typography>
            <Box
              component="form"
              sx={{ display: 'flex', flexDirection: 'column', gap: 2, mx: 'auto', my: 4 }}
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <TextField
                label="Grade"
                type="number"
                value={selectedTestData.results ?? ''}
                onChange={handleGradeChange}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                fullWidth
              />

              <TextField
                label="Feedback"
                value={selectedTestData.feedback ?? ''}
                onChange={handleFeedbackChange}
                multiline
                rows={20}
                variant="outlined"
                fullWidth
              />
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Box>
          </Box>
        )}
      </Stack>
      <Stack
        sx={{
          width: '100%',
          padding: 5,
          paddingTop: 10,
          backgroundColor: '',
          gridColumn: '2 / 3',
        }}
      >
        {selectedTestData && (
          <>
            <Typography variant="h6">Solution:</Typography>
            <AceEditor
              mode="javascript"
              readOnly={true}
              theme="monokai"
              value={selectedTestData.tests_data}
              name="code-editor"
              editorProps={{ $blockScrolling: true }}
              showPrintMargin={false}
              width="100%"
              height="calc(100vh - 50px)" // Adjust height to be full-page minus the select box and button height
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
              }}
              onLoad={(editor) => {
                editor.getSession().setUseWorker(false); // Disable syntax validation if needed
              }}
            />
          </>
        )}
      </Stack>
    </Box>
  );
}

export default AdminTestGrading;
