import * as React from 'react';
import TargetCard from 'components/TargetCard';
import PieChartVisualsization, { ChartProps } from 'components/charts/PieChartVisualsization';
import { AppContext } from 'context/context';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { LeetcodeLevels, Problem, TopicStatus } from 'interfaces/interfaces';
import {
  countFailedProblems,
  createTopicStatusMap,
  filterDataByTopicAndDifficulty,
  sortDataBySolveStatusAndAcRate,
  transformRawData,
} from 'utils/ProblemHelpers';
import { apiServerLink, localStorageKeys, themeColors } from 'sitevars';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Box,
  Stack,
  Grid,
  Divider,
} from '@mui/material';
import TrackerTable from 'components/TrackerTable';
import { TopicSummary } from 'components/TopicSummary';
import { TopicTrackerSectionTitle } from 'components/common/TopicTrackerSectionTitle';
import { PageHeader } from 'components/common/PageHeader';
import Spinner from 'components/common/Spinner';
import { getUserId } from 'utils/Helpers';

interface TopicTrackerProps {
  topicData: null;
}

export default function TopicTracker({ topicData }: TopicTrackerProps) {
  const { topic, difficulty } = useParams<{
    topic: string;
    difficulty: string;
  }>();
  const navigate = useNavigate();

  const difficultyEnum: LeetcodeLevels = difficulty as LeetcodeLevels;
  const { state, dispatch } = React.useContext(AppContext);
  const [filteredData, setFilteredData] = useState<Problem[]>([]);
  const [totalCount, setTotalCount] = useState<number | null>(0);
  const [targetCount, setTargetCount] = useState<number | null>(0);
  const [solvedCount, setSolvedCount] = useState<number | null>(0);
  const [failed, setFailedCount] = useState<number | null>(0);
  const [overviewChartProps, setOverviewChartProps] = useState<ChartProps[]>([]);
  const [targetChartProps, setTargetChartProps] = useState<ChartProps[]>([]);
  const [progress, setProgress] = useState<number>();
  const [loading, setLoading] = useState<boolean>(true);

  const fetchData = async () => {
    try {
      const userId = getUserId(state);
      const response = await axios.get(`${apiServerLink}/lc/targets?user_id=${userId}`);
      const transformedData = response.data;
      const parsedData = sortDataBySolveStatusAndAcRate(transformedData);
      if (parsedData) {
        if (!difficulty || !topic) {
          console.error('Topic Tracker params are not send correctly');
          navigate('/404');
        } else {
          const data = filterDataByTopicAndDifficulty(parsedData, difficulty, topic);
          if (data.length === 0) {
            navigate('/404');
          } else {
            setFilteredData(data);

            const total = data.length ?? 0;
            const target = data[0].topic_target ?? 0;
            const solved = data[0].count ?? 0;
            const failed = countFailedProblems(data);
            const unattempted = total - solved - failed;
            const currentProgress = parseFloat(((solved / target) * 100).toFixed(1));

            setTotalCount(total);
            setTargetCount(target);
            setSolvedCount(solved);
            setFailedCount(failed);
            setProgress(currentProgress);

            const overviewChartProps: ChartProps[] = [
              { id: 1, label: `Solved (${solved})`, value: solved, color: themeColors.deepGreen },
              { id: 2, label: `Failed (${failed})`, value: failed, color: themeColors.deepRed },
              { id: 3, label: `Left (${unattempted})`, value: unattempted, color: themeColors.deepBlue },
            ];
            setOverviewChartProps(overviewChartProps);

            const targetChartProps: ChartProps[] = [
              { id: 1, label: `Solved (${solved})`, value: solved, color: themeColors.deepGreen },
              { id: 3, label: `Target (${target})`, value: target - solved, color: themeColors.deepBlue },
            ];
            setTargetChartProps(targetChartProps);
            setLoading(false);
          }
        }
      } else {
        console.error("Couldn't get data from local storage properly");
        navigate('/404');
      }
    } catch (error) {
      console.error('There was an error fetching the data!', error);
      setLoading(false);
      return null;
    }
  };

  useEffect(() => {
    fetchData();
  }, [difficulty, topic, navigate]);

  return (
    <div>
      <Stack spacing={1} sx={{ backgroundColor: '', paddingBottom: 20 }}>
        <PageHeader title="Topic Targets" />
        {loading && <Spinner />}

        {!loading && filteredData && filteredData.length > 0 ? (
          <>
            <TopicSummary difficulty={difficultyEnum} topic={topic} progress={progress} />
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <TopicTrackerSectionTitle title="Tracker" />
                <TrackerTable filteredData={filteredData} />
              </Grid>

              {(targetChartProps || overviewChartProps) && (
                <Grid item xs={12} lg={6} sx={{ backgroundColor: '' }}>
                  {targetChartProps && overviewChartProps && <TopicTrackerSectionTitle title="Statistics" />}
                  {targetChartProps && <PieChartVisualsization chartProps={targetChartProps} title="Targets" />}
                  {overviewChartProps && <PieChartVisualsization chartProps={overviewChartProps} title="Overview" />}
                </Grid>
              )}
            </Grid>
          </>
        ) : (
          <Spinner />
        )}
      </Stack>
    </div>
  );
}
