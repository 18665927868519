import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { Button, ListItemButton, Tooltip, Typography, useTheme } from '@mui/material';
import { AppContext } from 'context/context';
import { useContext, useEffect, useState } from 'react';
import { localStorageKeys, themeColors, apiServerLink } from 'sitevars';
import axios from 'axios';
import { GoogleUserData, UserData } from 'interfaces/interfaces';
import { formatEpochToDate } from 'utils/Helpers';
import userLogo from 'assets/user_avatar.svg';
import { useAuth } from 'context/AuthContext';
import ExitToAppSharpIcon from '@mui/icons-material/ExitToAppSharp';
import { LogoutButton } from 'components/buttons/LogoutButton';
import DiscordButton from 'components/buttons/DiscordButton';
import PremiumButton from 'components/buttons/PremiumButton';
import GradingIcon from '@mui/icons-material/Grading';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  maxHeight: '80vh',
  padding: 5,
  bgcolor: 'background.paper',
  boxShadow: 10,
  overflowY: 'auto',
};

interface TestResultButtonModalProps {
  feedback: string;
  testTopic: string;
  grade: number;
}

export default function TestResultButtonModal({
  feedback,
  testTopic,
  grade,
}: TestResultButtonModalProps): React.JSX.Element {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const formattedFeedback = feedback
    .split('\n')
    .map((line, index) => (line === '' ? <br key={index} /> : <Typography key={index}>{line}</Typography>));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {}, []);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        backgroundColor: theme.palette.primary.light,
      }}
    >
      <>
        <Button
          sx={{
            textTransform: 'none',
            '&:hover': {
              backgroundColor: themeColors.muiPrimaryBlue,
            },
            width: '100%',
            padding: 0,
            margin: 0,
            fontSize: 14,
          }}
          onClick={handleOpen}
        >
          Check Results
        </Button>

        <Modal
          aria-labelledby="transition-modal-title-avatar"
          aria-describedby="transition-modal-description-avatar"
          open={open}
          onClose={handleClose}
          closeAfterTransition
        >
          <Fade in={open}>
            <Box sx={style}>
              <Typography
                sx={{
                  fontWeight: 300,
                  fontSize: 20,
                  borderBottom: '1px solid #ccc',
                  paddingBottom: 1,
                }}
              >
                Test Feedback for {testTopic}
              </Typography>
              <div style={{ padding: 10 }} />
              <Typography
                sx={{
                  fontSize: 18,
                  paddingBottom: 3,
                }}
              >
                Grade: <span style={{ color: themeColors.muiDarkBlue, fontWeight: 500 }}>{grade}%</span>
              </Typography>
              <>{formattedFeedback}</>
            </Box>
          </Fade>
        </Modal>
      </>
    </div>
  );
}
